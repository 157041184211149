import React, { useEffect, useMemo, useState, useCallback } from "react"
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import { Link, useNavigate, useParams } from "react-router-dom"
import MaterialReactTable from "material-react-table"
import { DateTime } from "luxon"
import {
    AttachMoney,
    Edit,
    PeopleOutlined,
    Visibility,
} from "@mui/icons-material"
import { useSnackbar } from "notistack"
import StatusButton from "../../Component/statusButton.jsx"
import usePartialUpdateEntity from "../../Hooks/usePartialUpdateEntity.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import customeFilter from "../../Component/customFilterPanel.jsx"
import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"

const timeRanges = [
    "12-1 ቀን",
    "1-2 ቀን",
    "2-3 ቀን",
    "3-4 ቀን",
    "4-5 ቀን",
    "5-6 ቀን",
    "6-7 ቀን",
    "7-8 ቀን",
    "8-9 ቀን",
    "9-10 ቀን",
    "10-11 ቀን",
    "11-12 ቀን",
    "12-1 ማታ",
    "1-2 ማታ",
    "2-3 ማታ",
    "3-4 ማታ",
    "4-5 ማታ",
    "5-6 ማታ",
    "6-7 ማታ",
    "7-8 ማታ",
    "8-9 ማታ",
    "9-10 ማታ",
    "10-11 ማታ",
    "11-12 ማታ",
]

const programFields = [
    {
        accessorKey: "name",
        header: "Name",
        size: 240,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "total_trips_taken",
        header: "Total Trips",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "monthly_trips_taken",
        header: "Monthly Trips",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "monthly_spent_amount",
        header: "Monthly Amount Spent",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "total_spent_amount",
        header: "Total Amount Spent",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "number_of_users",
        header: "Number of Users",
        filterVariant: "number",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "spending_limit_amount",
        header: "Spending Limit Amount",
        size: 240,
        headerAlign: "center",
        filterVariant: "number",
        align: "center",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell, row }) => {
            const isUnlimited = !row.original.spending_limit
            return isUnlimited ? (
                <Typography>Unlimited</Typography>
            ) : (
                cell.getValue()
            )
        },
    },
    {
        accessorKey: "spending_limit_duration",
        header: "Spending Limit Duration",
        size: 240,
        headerAlign: "center",
        filterVariant: "select",
        filterSelectOptions: ["weekly", "daily", "monthly"],
        align: "center",
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "trip_limit_amount",
        header: "Trip Limit Amount",
        size: 240,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => {
            const isUnlimited = cell.getValue() === 0
            return isUnlimited ? (
                <Typography>Unlimited</Typography>
            ) : (
                cell.getValue()
            )
        },
    },
    {
        accessorKey: "trip_limit_duration",
        header: "Trip Limit Duration",
        size: 240,
        filterVariant: "select",
        filterSelectOptions: ["weekly", "daily", "monthly"],
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "date_limit_weekdays",
        header: "Date Limit Weekdays",
        sortable: false,
        size: 240,
        filterVariant: "select",
        filterSelectOptions: ["M", "Tu", "W", "Th", "F", "Sat", "Sun"],
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ row }) => {
            if (row.original?.date_limit === false) return "No Date Limit"
            const days = row.original?.date_limit_weekdays
            return days?.map(
                (dayNumber) =>
                    `${["Sun", "M", "Tu", "W", "Th", "F", "Sat"][dayNumber]}, `
            )
        },
    },
    {
        accessorFn: (row) => {
            if (row.time_limit === false) return "No Time Limit"
            const times = row.time_limit_times
            return times?.map((time) => {
                if (time === 1) return `12-1 ቀን, `
                if (time <= 12) return `${time - 1}-${time} ቀን, `
                if (time === 13) return `12-1 ማታ, `
                return `${time - 13}-${time - 12} ማታ, `
            })
        },
        id: "time_limit_times",
        header: "Time Limit Times",
        size: 240,
        sortable: false,
        headerAlign: "center",
        filterVariant: "select",
        filterSelectOptions: timeRanges,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "expire_date",
        header: "Expire Date",
        filterVariant: "date",
        size: 240,
        muiTableHeadCellFilterTextFieldProps: ({ column }) => ({
            type: "date",
            onChange: (event) => {
                column?.setFilterValue(event?.target?.value)
            },
        }),
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell, row }) => {
            const noExpireDate = row.original?.has_expire_date === false
            if (noExpireDate) return <Typography>No Expire Date</Typography>
            const expired =
                DateTime.now().startOf("day") >
                DateTime.fromISO(cell.getValue()).startOf("day")
            return expired ? (
                <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: "#ffd6d6",
                        color: "red",
                        "&: hover": {
                            backgroundColor: "#ffd6d6",
                            color: "red",
                        },
                    }}
                >
                    Expired
                </Button>
            ) : (
                DateTime.fromISO(cell.getValue()).toFormat("ff")
            )
        },
    },
    {
        accessorFn: (row) => DateTime.fromISO(row.created_at).toFormat("ff"),
        id: "created_at",
        header: "Created At",
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 240,
    },
    {
        accessorKey: "status",
        header: "Actions",
        size: 360,
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        renderColumnFilterModeMenuItems: customeFilter,
    },
]

function Programs() {
    const {
        programsPage,
        programsCreate,
        programsDetail,
        programsList,
        programsUpdate,
        programsUsersList,
        programsTransactions,
    } = useUserPermissions()

    const { tId } = useParams()
    const endPoint = tId ? `teams/${tId}/programs` : "programs"
    const { md } = useBreakPoints()

    const { params, state, onChangeHandlers } = useTableState({
        columns: programFields,
    })

    const [total, setTotal] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    // TO KEEP THE PAGINATION STATE WHEN API ERRORS OUT
    useEffect(() => {
        setTotal((prevTotal) => (total !== undefined ? total : prevTotal))
    }, [total, setTotal])

    // HOOK TO UPDATE PROGRAMS, USED FOR STATUS CHANGE HERE
    const { mutate, isLoading: updating } = usePartialUpdateEntity(`programs`)

    // FILTER HANDLER, TO MAP TIME-RANGES AND DAY-LIMITS TO THEIR RESPECTIVE NUMBER
    const handleFilter = (filterArray) => {
        const filter = filterArray?.map((ele) => {
            const { value } = ele
            if (ele.column_field === "date_limit_weekdays") {
                const ind = ["M", "Tu", "W", "Th", "F", "Sat", "Sun"].indexOf(
                    value
                )
                return { ...ele, value: ind + 1 }
            }
            if (ele.column_field === "time_limit_times") {
                const ind = timeRanges?.indexOf(value)
                return { ...ele, value: ind + 1 }
            }
            return ele
        })

        return filter
    }

    const model = handleFilter(params.filterModel)
    const { filterModel, ...rest } = params
    const { data, isLoading, isFetching, refetch } = useFetchEntitiesPerPage(
        {
            endPoint: endPoint,
            filterModel: model,
            ...rest,
        },
        {
            enabled: programsList,
        }
    )
    // HANDLERS
    const handleStatusChange = useCallback(
        (checked, row) => {
            mutate(
                {
                    id: row?.id,
                    status: checked ? "ACTIVE" : "INACTIVE",
                },
                {
                    onError: (error) => {
                        enqueueSnackbar(
                            `status update failed due to ${error.message}`,
                            { variant: "error" }
                        )
                    },
                    onSuccess: () => {
                        enqueueSnackbar("status updated successfully", {
                            variant: "success",
                        })
                        refetch()
                    },
                }
            )
        },
        [enqueueSnackbar, mutate, refetch]
    )

    const customDateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )

    const actionColumn = useCallback(
        ({ cell, row }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "1.5em",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <StatusButton
                    loading={updating}
                    status={cell.getValue()}
                    onChange={(checked) =>
                        handleStatusChange(checked, row.original)
                    }
                />
                {!tId && (
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Link
                            to={`${row.original.id}/view`}
                            state={row.original}
                            style={{ pointerEvents: !programsDetail && "none" }}
                        >
                            <Tooltip title="see detail">
                                <IconButton
                                    disabled={!programsDetail}
                                    size="small"
                                    sx={{ color: "primary.main" }}
                                >
                                    <Visibility />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link
                            to={`${row.original.id}/edit`}
                            state={row.original}
                            style={{ pointerEvents: !programsUpdate && "none" }}
                        >
                            <Tooltip title="edit">
                                <IconButton
                                    disabled={!programsUpdate}
                                    size="small"
                                    sx={{ color: "primary.main" }}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link
                            to={`${row.original.id}/users`}
                            state={row.original}
                            style={{
                                pointerEvents: !programsUsersList && "none",
                            }}
                        >
                            <Tooltip title="program users">
                                <IconButton
                                    disabled={!programsUsersList}
                                    size="small"
                                    sx={{ color: "primary.main" }}
                                >
                                    <PeopleOutlined />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link
                            to={`${row.original.id}/transactions`}
                            state={row.original}
                            style={{
                                pointerEvents: !programsTransactions && "none",
                            }}
                        >
                            <Tooltip title="Transactions">
                                <IconButton
                                    disabled={!programsTransactions}
                                    size="small"
                                    sx={{ color: "primary.main" }}
                                >
                                    <AttachMoney />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </Box>
                )}
            </Box>
        ),
        [
            tId,
            updating,
            programsDetail,
            programsUpdate,
            programsUsersList,
            programsTransactions,
            handleStatusChange,
        ]
    )

    // ADD A RENDER METHOD TO `STATUS `COL DEFINITION
    const colFields = useMemo(
        () =>
            programFields.map((ele) => {
                if (
                    ele.id === "created_at" ||
                    ele.accessorKey === "expire_date"
                )
                    return {
                        ...ele,
                        Filter: customDateFilter,
                    }
                if (ele.accessorKey === "status")
                    return {
                        ...ele,
                        Cell: actionColumn,
                    }
                return ele
            }),
        [customDateFilter, actionColumn]
    )

    const handleProgramsExport = (pData) =>
        pData?.map((el) => ({
            ...el,
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
            expire_date: DateTime.fromISO(el.expire_date).toFormat("ff"),
            time_limit_times: `${el.time_limit_times}`,
            date_limit_weekdays: `${el.date_limit_weekdays}`,
        }))

    const { props } = useTableProps({
        columns: colFields,
        actionButtonProps: programsCreate && {
            actionName: "Add Programs",
            onClick: () => navigate("create"),
        },
        exportProps: {
            dataEndPoint: "programs",
            model: params.filterModel,
            formatter: handleProgramsExport,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        refetch: refetch,
    })

    useEffect(() => {
        if (data?.data?.meta_data.total) setTotal(data?.data?.meta_data.total)
    }, [data])

    // RESTRICT ACCESS IF USER HAS NO PERMISSION FOR THE PAGE
    if (!programsPage) return <RestrictedAccess />

    return (
        <Box
            sx={{
                backgroundColor: "white",
            }}
        >
            <MaterialReactTable
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        right: [md ? "status" : null],
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                {...props}
                {...onChangeHandlers}
            />
        </Box>
    )
}

export default Programs
