import React, { createContext, useMemo, useState } from "react"

const AuthContext = createContext({})

export function AuthProvider({ children }) {
    const savedCompany = window.localStorage.getItem("companyId")
    const savedTeam = window.localStorage.getItem("teamId")

    const [companyId, setCompanyId] = useState(savedCompany)
    const [teamId, setTeamId] = useState(savedTeam)

    const [auth, setAuth] = useState({})
    // const [sessionState, setSessionState] = useState("");

    const [sessionState, setSessionState] = useState(
        localStorage.getItem("session_state") || ""
    )
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem("test")) || false
    )
    const value = useMemo(
        () => ({
            auth,
            setAuth,
            persist,
            setPersist,
            sessionState,
            setSessionState,
            companyId,
            setCompanyId,
            teamId,
            setTeamId,
        }),
        [auth, persist, sessionState, companyId, teamId]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
