import React, { useState, useCallback, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    Grid,
    Box,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
    CircularProgress,
    DialogActions,
    Button,
} from "@mui/material"
import L from "leaflet"
import { debounce } from "lodash"
import "leaflet/dist/leaflet.css"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet"

import { MAP_API_URL } from "../../Utils/config.js"
import useCreateEntity from "../../Hooks/useCreateEntity.js"
import phoneRegex from "../../Utils/validationPattern.js"
import useSSOUsers from "../../Hooks/useSSOUsers.js"

// Default marker icon for Leaflet
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
    iconUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
})

// Fetch locations based on query
const fetchLocations = async (query) => {
    if (!query) return []

    try {
        const response = await fetch(`${MAP_API_URL}${query}`)

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        return data?.features.map((loc) => ({
            label: loc.properties.label,
            lat: loc.geometry.coordinates[1],
            lon: loc.geometry.coordinates[0],
        }))
    } catch (error) {
        console.error("Error fetching locations:", error)
        return []
    }
}

// Component to adjust the map view dynamically
function SetMapCenter({ position }) {
    const map = useMap()
    if (position) {
        map.setView(position, 13)
    }
    return null
}

// Debounce function
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay)
        return () => clearTimeout(handler)
    }, [value, delay])

    return debouncedValue
}

// Emergency Trip Ordering Form
function EmergencyTripFormDialog({ open, setOpen, refetch }) {
    const {
        control,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: {
            start_location: null,
            end_location: null,
            note: null,
            payment_type: "AUTOMATIC",
            phone: null,
        },
        mode: "onChange",
        reValidateMode: "onChange",
    })

    // const [submittedData, setSubmittedData] = useState(null)
    const [startLocation, setStartLocation] = useState(null)
    const [endLocation, setEndLocation] = useState(null)
    const [startQuery, setStartQuery] = useState("")
    const [endQuery, setEndQuery] = useState("")
    const [userId, setUserId] = useState(null)

    // Use debounce for controlled input
    const debouncedStartQuery = useDebounce(startQuery, 500)
    const debouncedEndQuery = useDebounce(endQuery, 500)

    // Debounce function using lodash
    const debouncedSetStartQuery = useCallback(
        () => debounce(setStartQuery, 500),
        []
    )
    const debouncedSetEndQuery = useCallback(
        () => debounce(setEndQuery, 500),
        []
    )

    const { enqueueSnackbar } = useSnackbar()

    const { mutate: searchUser } = useSSOUsers()

    // Emergency trip mutation
    const {
        mutate,
        isLoading,
        data: emergencyTrip,
        error,
    } = useCreateEntity(`emergency_trip`)

    // Fetch locations for start and end locations
    const { data: startOptions = [], isFetching: isStartLoading } = useQuery(
        ["startLocations", debouncedStartQuery],
        () => fetchLocations(debouncedStartQuery),
        { enabled: !!debouncedStartQuery }
    )

    const { data: endOptions = [], isFetching: isEndLoading } = useQuery(
        ["endLocations", debouncedEndQuery],
        () => fetchLocations(debouncedEndQuery),
        { enabled: !!debouncedEndQuery }
    )

    const handleClose = () => {
        setOpen(false)
    }

    const onSubmit = (data) => {
        if (startQuery === "") {
            setError("start_location", {
                type: "manual",
                message: "Start location is required",
            })
        }
        if (endQuery === "") {
            setError("end_location", {
                type: "manual",
                message: "End location is required",
            })
        }
        if (startQuery !== "" && endQuery !== "") {
            const payload = {
                ...data,
                start_location: data.start_location || startQuery,
                end_location: data.end_location || endQuery,
                user_id: userId,
            }
            mutate(payload)
        }
    }

    const phoneValue = watch("phone")
    useEffect(() => {
        if (phoneRegex.test(phoneValue)) {
            searchUser(
                { phones: [phoneValue] },
                {
                    onSuccess: (data) => {
                        if (data?.ok && data.data) {
                            setUserId(data.data[0].id)
                        } else {
                            setUserId(null)
                        }
                    },
                    onError: () => {
                        setUserId(null)
                    },
                }
            )
        }
    }, [searchUser, phoneValue])
    useEffect(() => {
        if (emergencyTrip) {
            enqueueSnackbar(`Succesfully ordered a new trip`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            handleClose()
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emergencyTrip])
    useEffect(() => {
        if (error) {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message

            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                    mb: 2,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    Order pre-RIDE Trip
                </Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {/* Phone */}
                        <Grid item xs={12}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: "Phone is required",
                                    pattern: {
                                        value: phoneRegex,
                                        message: "Must be a valid phone number",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Passenger Phone"
                                        fullWidth
                                        margin="dense"
                                        error={Boolean(errors.phone)}
                                        helperText={
                                            errors?.phone
                                                ? errors?.phone?.message
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {/* Start Location */}
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                options={startOptions}
                                getOptionLabel={(option) => option.label || ""}
                                onInputChange={(e, value) =>
                                    setStartQuery(value)
                                }
                                onChange={(e, value) => {
                                    if (value) {
                                        setStartLocation([value.lat, value.lon])
                                        setValue("start_location", value.label)
                                    } else {
                                        setValue("start_location", startQuery)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pick-up Location"
                                        fullWidth
                                        margin="dense"
                                        onChange={(e) =>
                                            debouncedSetStartQuery(
                                                e.target.value
                                            )
                                        }
                                        error={Boolean(errors.start_location)}
                                        helperText={
                                            errors.start_location?.message
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isStartLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            {startLocation && (
                                <Box
                                    sx={{
                                        height: 300,
                                        border: "1px solid #ccc",
                                        mt: 2,
                                    }}
                                >
                                    <MapContainer
                                        center={startLocation || [9.03, 38.74]}
                                        zoom={13}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                        <SetMapCenter
                                            position={startLocation}
                                        />
                                        {startLocation && (
                                            <Marker position={startLocation} />
                                        )}
                                    </MapContainer>
                                </Box>
                            )}
                        </Grid>

                        {/* End Location */}
                        <Grid item xs={12}>
                            <Autocomplete
                                freeSolo
                                options={endOptions}
                                getOptionLabel={(option) => option.label || ""}
                                onInputChange={(e, value) => setEndQuery(value)}
                                onChange={(e, value) => {
                                    if (value) {
                                        setEndLocation([value.lat, value.lon])
                                        setValue("end_location", value.label)
                                    } else {
                                        setValue("end_location", endQuery)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Drop-off Location"
                                        fullWidth
                                        margin="dense"
                                        onChange={(e) =>
                                            debouncedSetEndQuery(e.target.value)
                                        }
                                        error={Boolean(errors.end_location)}
                                        helperText={
                                            errors.end_location?.message
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {isEndLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            {endLocation && (
                                <Box
                                    sx={{
                                        height: 300,
                                        border: "1px solid #ccc",
                                        mt: 2,
                                    }}
                                >
                                    <MapContainer
                                        center={endLocation || [9.03, 38.74]}
                                        zoom={13}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                        <SetMapCenter position={endLocation} />
                                        {endLocation && (
                                            <Marker position={endLocation} />
                                        )}
                                    </MapContainer>
                                </Box>
                            )}
                        </Grid>

                        {/* Note */}
                        <Grid item xs={12}>
                            <Controller
                                name="note"
                                control={control}
                                rules={{ required: "Note is required" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Note"
                                        multiline
                                        fullWidth
                                        margin="dense"
                                    />
                                )}
                            />
                        </Grid>
                        {/* Payment Type */}
                        <Grid item xs={12}>
                            <Controller
                                name="payment_type"
                                control={control}
                                rules={{ required: "Payment Type is required" }}
                                render={({ field }) => (
                                    <FormControl component="fieldset">
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            Payment Type
                                        </Typography>
                                        <RadioGroup {...field} row>
                                            <FormControlLabel
                                                value="AUTOMATIC"
                                                control={<Radio />}
                                                label="Automatic"
                                            />
                                            <FormControlLabel
                                                value="MANUAL"
                                                control={<Radio />}
                                                label="Manual"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                sx={{
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        color: "primary.main",
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleClose}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": { bgcolor: "primary.main" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    <Typography>Submit</Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "common.main",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EmergencyTripFormDialog
