import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from "@mui/material"
import React, { useState } from "react"
import { CSVLink } from "react-csv"
import useBreakPoints from "../Hooks/useBreakPoints.js"
import AddUsers from "./addUsers.jsx"
import AssignSingleUser from "./assignSingleUser.jsx"
import CSVUploader from "./csvUploader.jsx"
import FullTextSearch from "./fullTextSearch.jsx"

function AddPeople({ open, setOpen, refetch }) {
    const [file, setFile] = useState(null)
    const [search, setSearch] = useState(null)

    const { xs, md } = useBreakPoints()

    const handleClose = () => {
        setSearch(null)
        setFile(null)
        setOpen(false)
    }

    const handleCsv = (data) => {
        setFile(data)
    }

    const handleChange = (val) => setSearch(val)

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            onClose={handleClose}
            open={open}
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
        >
            <DialogTitle
                sx={{
                    margin: "auto",
                    mb: 2,
                    boxShadow: 1,
                    py: 3,
                    width: "100%",
                    textAlign: "center",
                }}
            >
                Add New User
            </DialogTitle>
            <DialogContent>
                <Box>
                    {!file && (
                        <Box sx={{ mb: "1em" }}>
                            <FullTextSearch onChange={handleChange} />
                        </Box>
                    )}
                    {search ? (
                        <AssignSingleUser
                            search={search}
                            close={handleClose}
                            refetch={refetch}
                        />
                    ) : null}
                    {file && (
                        <AddUsers
                            file={file}
                            setFile={setFile}
                            close={handleClose}
                            refetch={refetch}
                        />
                    )}
                    {!!search ||
                        (!file && (
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "1em",
                                    }}
                                >
                                    <CSVUploader onChange={handleCsv} />
                                    <Button
                                        sx={{
                                            textDecoration: "underline",
                                            color: "secondary.main",
                                            mt: "1em",
                                        }}
                                    >
                                        <CSVLink
                                            data={[
                                                {
                                                    phone: "Write user phone number here",
                                                    programs:
                                                        "a string with comma separated program names",
                                                    guest: "yes or no",
                                                },
                                                {
                                                    phone: "251910222324",
                                                    programs: "testprogram",
                                                    guest: "yes",
                                                },
                                                {
                                                    phone: "251985764312",
                                                    programs: "beffkad",
                                                    guest: "no",
                                                },
                                            ]}
                                            style={{
                                                textDecoration: "inherit",
                                                color: "#000000",
                                            }}
                                            filename="bulk assign csv sample format.csv"
                                        >
                                            DownLoad Sample CSV
                                        </CSVLink>
                                    </Button>
                                </Box>
                                <Divider sx={{ my: "1em" }} />
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="common"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Box>
                        ))}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddPeople
