/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useState } from "react"

import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"

import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import { Close } from "@mui/icons-material"
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
} from "@mui/material"

import AuthContext from "../../Context/auth_provider.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"

const statusStyles = {
    PENDING: { color: "#ff9800" },
    APPROVED: { color: "#4caf50" },
    REJECTED: { color: "#f44336" },
    CANCELLED: { color: "black" },
}

function StatusChanger({ id, status, refetch }) {
    const { approveEmergencyTrips } = useUserPermissions()
    const [open, setOpen] = useState(false)
    const [btnAction, setBtnAction] = useState("")

    const { companyId, teamId } = useContext(AuthContext)
    const domain = teamId ? `/team/${teamId}` : `/corporate/${companyId}`
    const { enqueueSnackbar } = useSnackbar()
    const axiosPrivate = useAxiosPrivate()
    const { mutate, isLoading } = useMutation(
        (data) =>
            axiosPrivate
                .patch(`${domain}/emergency_trip/${id}/approve`, data)
                .then((res) => res.data),
        {
            onError: (error) => {
                enqueueSnackbar(
                    `Status update failed due to ${error.message}`,
                    {
                        variant: "error",
                    }
                )
                setOpen(false)
            },
            onSuccess: () => {
                enqueueSnackbar("Status updated successfully", {
                    variant: "success",
                })
                refetch()
                setOpen(false)
            },
        }
    )

    const handleStatusChange = useCallback((action) => {
        setBtnAction(action)
        mutate({
            status: action,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenDialog = () => {
        if (status === "PENDING") {
            setOpen(true)
        }
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ p: 2, textAlign: "center" }}>
            <Alert
                severity={
                    status === "PENDING"
                        ? "warning"
                        : status === "APPROVED"
                        ? "success"
                        : "error"
                }
                onClick={
                    status === "PENDING" && approveEmergencyTrips
                        ? handleOpenDialog
                        : undefined
                }
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: status === "PENDING" ? "pointer" : "default",
                    color: statusStyles[status].color,
                    width: 160,
                }}
            >
                {status}
            </Alert>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                        mb: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Confirm Status Change
                    </Typography>
                    <IconButton onClick={handleCloseDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ my: "1em" }}>
                    <DialogContentText sx={{ fontSize: "1.1em" }}>
                        Please select an action to either approve or reject the
                        trip.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        pr: 3,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            ":hover": { bgcolor: "red" },
                            backgroundColor: "red",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={() => {
                            handleStatusChange("REJECTED")
                        }}
                        disabled={isLoading}
                    >
                        Reject
                        {isLoading && btnAction === "REJECTED" && (
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                        )}
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": { bgcolor: "primary.main" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={() => {
                            handleStatusChange("APPROVED")
                        }}
                        disabled={isLoading}
                    >
                        Approve
                        {isLoading && btnAction === "APPROVED" && (
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default StatusChanger
