import React, { useContext, useRef, useState } from "react"
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material"
import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import useSSOUsers from "../Hooks/useSSOUsers.js"
import FullTextSearch from "./fullTextSearch.jsx"
import LoadingPage from "./loadingPage.jsx"
import useFetchEntities from "../Hooks/useFetchEntity.js"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import useBreakPoints from "../Hooks/useBreakPoints.js"
import { SSO_ASSETS_URL, SSO_BASE_URL, SSO_VERSION } from "../Utils/config.js"

function User({ name, phone, src }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "common.main",
                py: "1em",
                px: "1em",
            }}
        >
            <Avatar
                style={{
                    height: "3em",
                    width: "3em",
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
                src={src || " "}
            />
            <Typography
                variant="body1"
                sx={{ fontSize: "1.2em", textAlign: "center" }}
            >
                {name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2em" }}>
                +{phone}
            </Typography>
        </Box>
    )
}

function AddSystemUser({ open, setOpen }) {
    const {
        data: user,
        mutate,
        isLoading,
        isSuccess,
        isError,
        isIdle,
        reset,
    } = useSSOUsers()

    const { xs, md } = useBreakPoints()

    const {
        first_name: fname,
        last_name: lname,
        middle_name: mname,
        phone,
        id,
        profile_picture: src,
    } = user?.data?.[0] || {}

    const [role, setRole] = useState(null)
    const ref = useRef(null)

    const { data } = useFetchEntities("roles")

    const { enqueueSnackbar } = useSnackbar()
    const { companyId, teamId } = useContext(AuthContext)
    const domain = teamId ? `team/${teamId}` : `corporate/${companyId}`
    const axiosPrivate = useAxiosPrivate()

    const queryClient = useQueryClient()

    const { mutate: assignRole, isLoading: updatingRole } = useMutation(
        (body) =>
            axiosPrivate.post(`${domain}/users/${id}/roles`, {
                role: body.id,
            }),
        {
            onSuccess: () => {
                enqueueSnackbar("Role Assigned Successfully", {
                    variant: "success",
                })
                queryClient.invalidateQueries({
                    queryKey: [domain, "system/users"],
                })
                reset()
                setRole(null)
                setOpen(false)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError || errorMessage || "Request Failed",
                    { variant: "error" }
                )
            },
        }
    )

    const handleSearch = (val) => {
        mutate({ phones: [val] })
    }

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
            open={open}
            onClose={() => {
                reset()
                setRole(null)
                setOpen(false)
            }}
        >
            <DialogTitle
                sx={{
                    textAlign: "center",
                    backgroundColor: "#fafafa",
                    mb: "1em",
                    fontWeight: 550,
                    letterSpacing: ".01em",
                    wordSpacing: ".2em",
                }}
            >
                Add New System User
            </DialogTitle>
            <DialogContent
                sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
                <FullTextSearch onChange={handleSearch} />
                {isIdle && (
                    <Typography variant="subtitle1" sx={{ ml: ".5em" }}>
                        Search users by phone to add them as a system user
                    </Typography>
                )}
                {isLoading && <LoadingPage message="...Wait a second" />}

                {phone && (
                    <User
                        name={`${fname} ${mname} ${lname}`}
                        phone={phone}
                        src={`${SSO_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${src}`}
                    />
                )}
                {isSuccess && !phone && <p>User is not registed in ridePlus</p>}
                {isError && (
                    <p>something went wrong please try a moment later</p>
                )}
                {phone && (
                    <Autocomplete
                        value={role}
                        options={data?.data?.data || []}
                        getOptionLabel={(option) => option?.name}
                        onChange={(e, newVal) => setRole(newVal)}
                        renderInput={(params) => (
                            <TextField
                                inputRef={ref}
                                label="choose role"
                                {...params}
                            />
                        )}
                    />
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: "#fafafa",
                    pr: md ? "1.55em" : undefined,
                }}
            >
                <Button
                    variant="contained"
                    color="common"
                    onClick={() => {
                        setOpen(false)
                        setRole(null)
                        reset()
                    }}
                >
                    Cancel
                </Button>
                {id && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (role) {
                                assignRole(role)
                            } else {
                                ref.current.focus()
                            }
                        }}
                        startIcon={
                            updatingRole ? (
                                <CircularProgress
                                    size="1em"
                                    sx={{ color: "white" }}
                                />
                            ) : null
                        }
                    >
                        Add
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default AddSystemUser
