import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { Delete } from "@mui/icons-material"
import { DateTime } from "luxon"

import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import GenerateApiKey from "./generateApiKey.jsx"
import DeleteEntity from "../../Component/deleteDialog.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"

const apiKeyFields = [
    {
        accessorFn: (row) => DateTime.fromISO(row.created_at).toFormat("ff"),
        id: "created_at",
        header: "Created At",
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 240,
    },
    {
        accessorKey: "name",
        header: "Name",
        size: 240,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "expire_at",
        header: "Expire Date",
        filterVariant: "date",
        size: 240,
        muiTableHeadCellFilterTextFieldProps: ({ column }) => ({
            type: "date",
            onChange: (event) => {
                column?.setFilterValue(event?.target?.value)
            },
        }),
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => {
            const expired =
                DateTime.now().startOf("hour") >
                DateTime.fromISO(cell.getValue()).startOf("hour")
            return expired ? (
                <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: "#ffd6d6",
                        color: "red",
                        "&: hover": {
                            backgroundColor: "#ffd6d6",
                            color: "red",
                        },
                    }}
                >
                    Expired
                </Button>
            ) : (
                DateTime.fromISO(cell.getValue()).toFormat("ff")
            )
        },
    },
    {
        accessorKey: "action",
        header: "Actions",
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
    },
]

function ApiKeys() {
    const { md } = useBreakPoints()
    const { generateApiKey, apiKeysList, apiKeyDelete } = useUserPermissions()

    const { params, state, onChangeHandlers } = useTableState({
        columns: apiKeyFields,
    })

    const [total, setTotal] = useState(0)
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const apiKey = useRef()

    // TO KEEP THE PAGINATION STATE WHEN API ERRORS OUT
    useEffect(() => {
        setTotal((prevTotal) => (total !== undefined ? total : prevTotal))
    }, [total, setTotal])

    const customDateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )
    const actionColumn = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "1.5em",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Tooltip title="Delete Api Key">
                    <IconButton
                        size="small"
                        color="error"
                        onClick={() => {
                            apiKey.current = row?.original?.id
                            setOpenDelete(true)
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        []
    )
    const colFields = useMemo(
        () =>
            apiKeyFields.map((ele) => {
                if (ele.id === "created_at" || ele.accessorKey === "expire_at")
                    return {
                        ...ele,
                        Filter: customDateFilter,
                    }
                if (ele.accessorKey === "action" && apiKeyDelete)
                    return {
                        ...ele,
                        Cell: actionColumn,
                    }
                return ele
            }),
        [actionColumn, apiKeyDelete, customDateFilter]
    )

    const handleVouchersExport = (data) =>
        data?.map((el) => ({
            ...el,
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            expire_at: DateTime.fromISO(el.expire_at).toFormat("ff"),
        }))

    const { filterModel, ...rest } = params
    const { data, isLoading, isFetching, refetch } = useFetchEntitiesPerPage(
        {
            endPoint: `apikey`,
            filterModel: params.filterModel,
            ...rest,
        },
        {
            enabled: apiKeysList,
        }
    )

    const { props } = useTableProps({
        columns: colFields,
        actionButtonProps: generateApiKey && {
            actionName: "Generate API Key",
            onClick: () => setOpen(true),
        },
        exportProps: {
            dataEndPoint: `apikey`,
            model: params.filterModel,
            formatter: handleVouchersExport,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        refetch: refetch,
    })

    useEffect(() => {
        if (data?.data?.meta_data.total) setTotal(data?.data?.meta_data.total)
    }, [data])

    if (!apiKeysList) return <RestrictedAccess />

    return (
        <Box>
            {open && (
                <GenerateApiKey
                    open={open}
                    refetch={refetch}
                    handleClose={() => setOpen(false)}
                />
            )}
            {openDelete && (
                <DeleteEntity
                    open={openDelete}
                    setOpen={setOpenDelete}
                    deleteUrl={`apikey/${apiKey.current}`}
                    message="Api Key"
                    refetch={refetch}
                />
            )}
            <Box
                sx={{
                    backgroundColor: "white",
                }}
            >
                <MaterialReactTable
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            right: [md ? "status" : null],
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default ApiKeys
