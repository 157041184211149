import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { Box, Hidden, Slide } from "@mui/material"
import { SnackbarProvider } from "notistack"
import { ReactQueryDevtools } from "react-query/devtools"
import Main from "./Container/main.jsx"
import PrivateRoute from "./Component/private_route.jsx"
import { AuthProvider } from "./Context/auth_provider.jsx"
import PersistLogin from "./Component/persistLogin.jsx"
import Authorize from "./Component/authorize.js"
import IPIframe from "./Component/ipIframe.jsx"
import Listener from "./Component/listener.jsx"
import SignIn from "./Pages/signIn.jsx"
import Roles from "./Pages/Roles/roles.jsx"
import CreateProgram from "./Pages/programs/createProgram.jsx"
import Programs from "./Pages/programs/programs.jsx"
import ProgramTransactions from "./Pages/programs/programTransactions.jsx"
import ProgramDetails from "./Pages/programs/programDetails.jsx"
import CompanyUsers from "./Pages/People/companyUsers.jsx"
import UpdateProgram from "./Pages/programs/updateProgram.jsx"
import CompanyTransactions from "./Pages/transactions/companyTransactions.jsx"
import SystemUsers from "./Pages/SystemUsers/systemUsers.jsx"
import UserCompany from "./Pages/SystemUsers/companyList.jsx"
import Invoice from "./Pages/Invoice/invoice.jsx"
import Config from "./Pages/configuration/config.jsx"
import NotFound from "./Pages/404.jsx"
import ProgramUsers from "./Pages/programs/programUsers.jsx"
// import { BMS_BASE_URL, SSO_BASE_URL } from "./Utils/config.js"
import PeopleTransactions from "./Pages/People/peopleTransaction.jsx"
import InvoiceDetail from "./Pages/Invoice/invoiceDetail.jsx"
import TopUps from "./Pages/Invoice/topups.jsx"
import useAddToHomescreenPrompt from "./Hooks/useAddToHomescreenPrompt.js"
import ViewSystemUser from "./Pages/SystemUsers/viewSystemUser.jsx"
import Vouchers from "./Pages/voucher/voucher.jsx"
import VoucherUsers from "./Pages/voucher/voucherUsers.jsx"
import ApiKeys from "./Pages/apiKeys/apiKeys.jsx"
import Discounts from "./Pages/discount/discounts.jsx"
import DiscountUsers from "./Pages/discount/discountUsers.jsx"
import Receipts from "./Pages/Receipts/receipts.jsx"
import DownloadReceipt from "./Pages/Receipts/downloadReceipt.jsx"
import EmergencyTrips from "./Pages/Emergency Trips/emergencyTrips.jsx"
import Teams from "./Pages/Teams/teams.jsx"
import TeamSystemUsers from "./Pages/Teams/teamSystemUsers.jsx"

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: false,
        },
    },
})

function App() {
    const [prompt, setState, promptToInstall] = useAddToHomescreenPrompt()
    return (
        <Box>
            {/* Single Sign out Iframe */}
            {/* {window.parent === window && (
                <iframe
                    id="ipIframe"
                    src={`${BMS_BASE_URL}/IPIFrame`}
                    title="ipIframe"
                    style={{ display: "none" }}
                />
            )}
            {window.parent === window && (
                <iframe
                    id="opIframe"
                    src={`${SSO_BASE_URL}/opIframe`}
                    title="opIframe"
                    style={{ display: "none" }}
                />
            )} */}
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    TransitionComponent={Slide}
                    autoHideDuration={3000}
                    preventDuplicate
                >
                    <BrowserRouter>
                        <AuthProvider>
                            {window.parent === window && (
                                <Hidden>
                                    <Listener id="listener" />
                                </Hidden>
                            )}
                            <Routes>
                                <Route path="login" element={<SignIn />} />
                                <Route
                                    path="authorize"
                                    element={<Authorize />}
                                />
                                <Route path="IPIFrame" element={<IPIframe />} />
                                <Route element={<PersistLogin />}>
                                    <Route
                                        path="/invoice/:invoiceNumber/detail/:transactionId"
                                        element={
                                            <PrivateRoute>
                                                <DownloadReceipt />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/receipts/:transactionId"
                                        element={
                                            <PrivateRoute>
                                                <DownloadReceipt />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/topups/receipts/:transactionId"
                                        element={
                                            <PrivateRoute>
                                                <DownloadReceipt />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <PrivateRoute>
                                                <UserCompany>
                                                    <Main
                                                        prompt={prompt}
                                                        setState={setState}
                                                        promptToInstall={
                                                            promptToInstall
                                                        }
                                                    />
                                                </UserCompany>
                                            </PrivateRoute>
                                        }
                                    >
                                        <Route
                                            path="departments"
                                            element={<Teams />}
                                        />
                                        <Route
                                            path="departments/:tId/system-users"
                                            element={<TeamSystemUsers />}
                                        />
                                        <Route
                                            path="departments/:tId/people"
                                            element={<CompanyUsers />}
                                        />
                                        <Route
                                            path="departments/:tId/people/:userId/transactions"
                                            element={<PeopleTransactions />}
                                        />
                                        <Route
                                            path="departments/:tId/programs"
                                            element={<Programs />}
                                        />
                                        <Route
                                            path="departments/:tId/transactions"
                                            element={<CompanyTransactions />}
                                        />
                                        <Route
                                            path="programs"
                                            element={<Programs />}
                                        />
                                        <Route
                                            path="programs/create"
                                            element={<CreateProgram />}
                                        />
                                        <Route
                                            path="roles"
                                            element={<Roles />}
                                        />
                                        <Route
                                            path="topups"
                                            element={<TopUps />}
                                        />
                                        <Route
                                            path="invoice"
                                            element={<Invoice />}
                                        />
                                        <Route
                                            path="receipts"
                                            element={<Receipts />}
                                        />
                                        <Route
                                            path="invoice/:invoiceNumber/detail"
                                            element={<InvoiceDetail />}
                                        />
                                        <Route
                                            path="config"
                                            element={<Config />}
                                        />
                                        <Route
                                            path="system-users"
                                            element={<SystemUsers />}
                                        />
                                        <Route
                                            path="system-users/:userId/view"
                                            element={<ViewSystemUser />}
                                        />
                                        <Route
                                            path="programs/:programId/transactions"
                                            element={<ProgramTransactions />}
                                        />
                                        <Route
                                            path="programs/:programId/edit"
                                            element={<UpdateProgram />}
                                        />
                                        <Route
                                            path="programs/:programId/users"
                                            element={<ProgramUsers />}
                                        />
                                        <Route
                                            path="programs/:programId/view"
                                            element={<ProgramDetails />}
                                        />
                                        <Route
                                            path="transactions"
                                            element={<CompanyTransactions />}
                                        />
                                        <Route
                                            path="pre-RIDE"
                                            element={<EmergencyTrips />}
                                        />
                                        <Route
                                            path="users"
                                            element={<CompanyUsers />}
                                        />
                                        <Route
                                            path="users/:userId/transactions"
                                            element={<PeopleTransactions />}
                                        />
                                        <Route
                                            path="vouchers"
                                            element={<Vouchers />}
                                        />
                                        <Route
                                            path="vouchers/:voucherId/users"
                                            element={<VoucherUsers />}
                                        />
                                        <Route
                                            path="api-keys"
                                            element={<ApiKeys />}
                                        />
                                        <Route
                                            path="discounts"
                                            element={<Discounts />}
                                        />
                                        <Route
                                            path="discounts/:discountId/users"
                                            element={<DiscountUsers />}
                                        />
                                        <Route
                                            path="*"
                                            element={<NotFound />}
                                        />
                                    </Route>
                                </Route>
                            </Routes>
                        </AuthProvider>
                    </BrowserRouter>
                </SnackbarProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Box>
    )
}

export default App
