import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

// Partial Entity Update Hook
/* 
    modifying entity where the client sends partial data that is 
    to be updated without modifying the entire entity data.
*/
const usePartialUpdateEntity = (page) => {
    const queryClient = useQueryClient()
    const { companyId, teamId } = useContext(AuthContext)
    const domain = teamId ? `/team/${teamId}` : `/corporate/${companyId}`

    const axiosPrivate = useAxiosPrivate()

    return useMutation(
        (data) =>
            axiosPrivate
                .patch(`${domain}/${page}/${data.id}`, data)
                .then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [companyId, page] })
            },
        }
    )
}

export default usePartialUpdateEntity
