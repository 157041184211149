import { useRef } from "react"
import { find, isEqual } from "lodash"
import { useSnackbar } from "notistack"

import useCreateEntity from "./useCreateEntity.js"

const useAddMembers = () => {
    const { enqueueSnackbar } = useSnackbar()

    const user = useRef([])

    const { mutate, isLoading } = useCreateEntity(`users/seed`)

    const handleUserAdd = (value) => {
        if (find(user.current, (el) => isEqual(el, value))) return
        user.current = [...user.current, value]
    }

    return {
        addUser: handleUserAdd,
        isLoading,
        requestAdd: (callback) => {
            if (user.current.length === 0) {
                enqueueSnackbar(
                    "None of the phone are on RidePlus",
                    {
                        variant: "warning",
                    }
                )
                return
            }
            mutate({ users_id: user.current }, {
                onSuccess: () => {
                    enqueueSnackbar("User Added Successfully", {
                        variant: "success",
                    })
                    callback()
                },
                onError: (error) => {
                    const fieldError =
                        error.response?.data?.error?.field_error[0]?.description
                    const errorMessage = error.response?.data?.error?.message
                    enqueueSnackbar(
                        fieldError || errorMessage || "Request Failed",
                        { variant: "error" }
                    )
                },
            })
        },
        clearUser: () => {
            user.current = []
        },
    }
}

export default useAddMembers
