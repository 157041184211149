import { useQuery, useQueryClient } from "react-query"
import React, { useContext, useMemo, useState, useEffect } from "react"
import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material"

import bmsPic from "../../Assets/bmsCropped.png"
import fallback from "../../Assets/fallback.png"

import useFetchEntities from "../../Hooks/useFetchEntity.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

import AuthContext from "../../Context/auth_provider.jsx"
import LoadingPage from "../../Component/loadingPage.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import { RP_ASSETS_URL, BASE_URL, VERSION } from "../../Utils/config.js"

export function Image(props) {
    const { src, alt, fit, text } = props
    return (
        <Box
            sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
            <img
                width="100%"
                height="100%"
                src={src}
                onError={(e) => {
                    e.currentTarget.src = fallback
                }}
                alt={alt}
                style={{ objectFit: fit }}
            />
            {text && (
                <Typography
                    variant="h4"
                    sx={{
                        position: "absolute",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        bottom: "1em",
                        color: "white",
                        letterSpacing: ".08em",
                    }}
                >
                    {text}
                </Typography>
            )}
        </Box>
    )
}

function Company({ company, onSelect, selected }) {
    const { logo, name, id } = company
    return (
        <Paper
            sx={{
                p: ".8em",
                "&:hover": {
                    backgroundColor: "lightgray",
                },
                border: selected ? "2px solid black" : "none",
                cursor: "pointer",
                "&.MuiPaper-elevation": {
                    boxShadow: "2px 3px 3px #00000021",
                },
            }}
        >
            <Grid
                container
                spacing={1.2}
                alignItems="center"
                onClick={() => onSelect(id)}
            >
                <Grid
                    item
                    xs={2}
                    sm={1}
                    md={2}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Image
                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/company_logo/${logo}`}
                            alt="logo"
                            fit="fill"
                        />
                    </Box>
                </Grid>
                <Grid item xs={7} md={9}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        {name}
                    </Typography>
                    <Typography> Employees</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

function Team({ team, onSelect, selected }) {
    const { name, id } = team
    return (
        <Paper
            sx={{
                p: ".8em",
                "&:hover": {
                    backgroundColor: "lightgray",
                },
                border: selected ? "2px solid black" : "none",
                cursor: "pointer",
                "&.MuiPaper-elevation": {
                    boxShadow: "2px 3px 3px #00000021",
                },
            }}
        >
            <Grid
                container
                spacing={1.2}
                alignItems="center"
                onClick={() => onSelect(id)}
            >
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        {name}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const CompanyToggle = React.createContext()
export const CompanyInfo = React.createContext()
export const TeamInfo = React.createContext()

function UserCompany({ children }) {
    const axiosPrivate = useAxiosPrivate()

    const savedCompany = window.localStorage.getItem("companyId")
    const savedTeam = window.localStorage.getItem("teamId")

    const [selected, setSelected] = useState(savedCompany)
    const [selectedTeam, setSelectedTeam] = useState(savedTeam)
    const [teamSelectionMode, setTeamSelectionMode] = useState(false)

    const { companyId, setCompanyId, teamId, setTeamId } =
        useContext(AuthContext)
    const queryClient = useQueryClient()
    const { data, isSuccess, isError, isLoading, refetch } = useQuery(
        ["users", "permissions"],
        () =>
            axiosPrivate
                .get("users/permissions", {
                    params: {
                        per_page: -1,
                    },
                })
                .then((res) => res.data)
    )
    const {
        isLoading: teamsLoading,
        data: teamPermission,
        refetch: dataRefetch,
    } = useQuery(["team", "permissions"], () =>
        axiosPrivate
            .get("users/team/permissions", {
                params: {
                    per_page: -1,
                },
            })
            .then((res) => res.data)
    )

    const { data: teamAllPermissions } = useFetchEntities(
        `permissions/team`,
        {
            enabled: !!teamId,
        },
        teamId
    )

    const teamsList = useMemo(
        () =>
            teamPermission?.data
                ?.filter((el) => el?.team?.company?.id === selected)
                ?.map((el) => el?.team)
                ?.filter((team) => team?.status === "ACTIVE") || [],
        [selected, teamPermission?.data]
    )
    const hasTeams = teamsList?.length > 0

    const companyList = useMemo(() => {
        const company =
            data?.data?.length > 0 ? data?.data?.map((el) => el?.company) : []
        const teamCompanies =
            teamPermission?.data?.length > 0
                ? teamPermission?.data?.map((el) => el?.team?.company)
                : []
        return [
            ...new Map(
                [...company, ...teamCompanies].map((item) => [item.id, item])
            ).values(),
        ]
    }, [data?.data, teamPermission?.data])

    const contextValue = useMemo(
        () => ({
            toggle: () => {
                setSelected(false)
                setSelectedTeam(false)
                queryClient.invalidateQueries({
                    queryKey: ["users", "teams", companyId],
                })
            },
            companyList,
            data: data?.data,
        }),
        [companyId, companyList, data?.data, queryClient]
    )

    // SELECTED COMPANY INFO FOR COMPANY INFO CONTEXT
    const companyInfo = useMemo(
        () => ({
            refetch: refetch, // NEEDED TO UPDATE COMPANY INFORMATION LIKE TIPPING
            selectedCompany: companyList?.filter(
                (comp) => comp.id === selected
            )?.[0],
            userPermissions: data?.data?.filter(
                (el) => el.company.id === selected
            )?.[0]?.permissions, // user assigned permissions
        }),
        [refetch, companyList, data?.data, selected]
    )

    // TEAM INFO CONTEXT
    const teamInfo = useMemo(
        () => ({
            refetch: dataRefetch,
            selectedTeam:
                teamsList?.find((team) => team.id === selectedTeam) || null,
            userPermissions: teamPermission?.data?.filter(
                (el) => el.team.id === selectedTeam
            )?.[0]?.permissions, // user assigned permissions
            teamDomainPermissionsList: teamAllPermissions?.data?.data?.map(
                (item) => item.name
            ), // Team domain all permissions list
        }),
        [
            dataRefetch,
            teamsList,
            teamPermission?.data,
            teamAllPermissions,
            selectedTeam,
        ]
    )
    useEffect(() => {
        if (teamId) dataRefetch()
    }, [dataRefetch, teamId])

    // Handle company selection
    const handleCompanySelect = (compId) => {
        setCompanyId(compId)
        setTeamId(null)
        setSelectedTeam(null)
        setTeamSelectionMode(true)
    }

    // Handle team selection
    const handleTeamSelect = (id) => {
        setTeamId(id)
    }

    // If company selected but not confirmed yet
    const isCompanySelectionStep = !selected && companyList?.length >= 1

    // Team selection step - when company is selected and confirmed, and user has teams
    const isTeamSelectionStep = teamSelectionMode && hasTeams

    // Continue after company selection
    const handleContinueAfterCompany = () => {
        if (companyId) {
            window.localStorage.setItem("companyId", companyId)
            setTeamSelectionMode(true)
            setSelected(companyId)
        }
    }

    // Continue after team selection
    const handleContinueAfterTeam = () => {
        if (teamId) {
            window.localStorage.setItem("teamId", teamId)
            setSelectedTeam(teamId)
        } else {
            // If no team is selected but user has teams, store null for team
            window.localStorage.setItem("teamId", null)
            setSelectedTeam(null)
        }

        setTeamSelectionMode(false)
        setSelected(companyId)
    }

    // Skip team selection
    const handleSkipTeamSelection = () => {
        setTeamId(null)
        setSelectedTeam(null)
        setSelected(companyId)
        setTeamSelectionMode(false)
        window.localStorage.removeItem("teamId")
    }

    if (isCompanySelectionStep) {
        return (
            <Grid
                container
                spacing={1}
                sx={{
                    width: { lg: "80%", md: "90%", xs: "95%" },
                    display: "flex",
                    margin: "auto",
                    marginTop: { md: "5em" },
                    marginLeft: { lg: "6em" },
                    justifyContent: "space-between",
                }}
            >
                <Grid item md={6} xs={12} sx={{ p: ".5em" }}>
                    <Image src={bmsPic} alt="company-list" fit="cover" />
                </Grid>
                <Grid item md={5} xs={12}>
                    <Box
                        sx={{
                            p: "2em",
                            pt: "1em",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                        }}
                    >
                        <Typography sx={{ fontWeight: "500" }}>
                            Choose company and continue
                        </Typography>
                        {companyList?.map((comp) => (
                            <Company
                                key={comp.id}
                                company={comp}
                                selected={companyId === comp.id}
                                onSelect={handleCompanySelect}
                            />
                        ))}
                        <Button
                            disabled={!companyId}
                            variant="contained"
                            sx={{
                                height: "3em",
                                color: "#fff",
                                backgroundColor: "gray",
                            }}
                            onClick={handleContinueAfterCompany}
                        >
                            Continue
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (isTeamSelectionStep) {
        return (
            <Grid
                container
                spacing={1}
                sx={{
                    width: { lg: "80%", md: "90%", xs: "95%" },
                    display: "flex",
                    margin: "auto",
                    marginTop: { md: "5em" },
                    marginLeft: { lg: "6em" },
                    justifyContent: "space-between",
                }}
            >
                <Grid item md={6} xs={12} sx={{ p: ".5em" }}>
                    <Image src={bmsPic} alt="team-list" fit="cover" />
                </Grid>
                <Grid item md={5} xs={12}>
                    <Box
                        sx={{
                            p: "2em",
                            pt: "1em",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                        }}
                    >
                        <Typography sx={{ fontWeight: "500" }}>
                            Choose department and continue
                        </Typography>
                        {teamsLoading ? (
                            <Typography>Loading departments...</Typography>
                        ) : (
                            <>
                                {teamsList.map((team) => (
                                    <Team
                                        key={team.id}
                                        team={team}
                                        selected={teamId === team.id}
                                        onSelect={handleTeamSelect}
                                    />
                                ))}
                            </>
                        )}
                        <Box sx={{ display: "flex", gap: "1em" }}>
                            <Tooltip title="Login with the selected company">
                                <Button
                                    variant="outlined"
                                    sx={{
                                        height: "3em",
                                        flex: 1,
                                    }}
                                    onClick={handleSkipTeamSelection}
                                >
                                    Skip
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title="Login with the selected team"
                                disabled={!teamId}
                            >
                                <Button
                                    disabled={!teamId}
                                    variant="contained"
                                    sx={{
                                        height: "3em",
                                        color: "#fff",
                                        backgroundColor: "gray",
                                        flex: 1,
                                    }}
                                    onClick={handleContinueAfterTeam}
                                >
                                    Continue
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    if (selected && !companyId)
        return (
            <Box sx={{ pt: "5em", color: "secondary.main" }}>
                <LoadingPage message="...Please wait a moment" />
            </Box>
        )

    return (
        <CompanyToggle.Provider value={contextValue}>
            {companyList?.length > 0 && selected && !isLoading && (
                <CompanyInfo.Provider value={companyInfo}>
                    <TeamInfo.Provider value={teamInfo}>
                        {children}
                    </TeamInfo.Provider>
                </CompanyInfo.Provider>
            )}
            {companyList?.length === 0 && isSuccess && <RestrictedAccess />}
            {isError && !selected && (
                <p>
                    Something went wrong. <br /> Please Retry after a moment
                </p>
            )}
            {isLoading && (
                <Box sx={{ pt: "5em", color: "secondary.main" }}>
                    <LoadingPage message="...Please wait a moment" />
                </Box>
            )}
        </CompanyToggle.Provider>
    )
}

export default UserCompany
