import React, { useContext, useMemo } from "react"
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
} from "@mui/material"
import { Cancel } from "@mui/icons-material"
import { DataGrid } from "@mui/x-data-grid"
import { useQuery } from "react-query"
import { find, reject } from "lodash"

import isPhone from "../../Utils/isPhone.js"
import useAddMembers from "../../Hooks/useAddMembers.js"
import AuthContext from "../../Context/auth_provider.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

const user = [
    {
        field: "profile_picture",
        headerName: "Photo",
        headerClassName: "white-header",
        flex: 1,
        sortable: false,
    },
    {
        field: "name",
        headerName: "Name",
        headerClassName: "white-header",
        flex: 3,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "phone",
        headerName: "Phone",
        headerClassName: "white-header",
        flex: 2,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "action",
        headerName: "Action",
        headerClassName: "white-header",
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
]

function AddBulkMember({ file, setFile, close }) {
    const axiosPrivate = useAxiosPrivate()
    const { companyId } = useContext(AuthContext)

    const {
        addUser,
        clearUser,
        requestAdd,
        isLoading: adding,
    } = useAddMembers()

    const {
        data: users,
        isError,
        error,
    } = useQuery(
        ["trip_users"],
        () =>
            axiosPrivate
                .post(`corporate/${companyId}/users/phone`, {
                    phones: file
                        .filter((ele) => !!ele?.phone)
                        .map((el) => el.phone),
                })
                .then((res) => res.data),
        {
            retry: false,
        }
    )

    const combinedArray = useMemo(
        () =>
            users?.data?.map((item2) => {
                addUser(item2.id)

                return {
                    ...item2,
                    name: `${item2.first_name ?? ""} ${
                        item2.middle_name ?? ""
                    } ${item2.last_name ?? ""}`,
                }
            }) || [],
        [users?.data, addUser]
    )

    const handleCancel = (data) => {
        clearUser()
        const removed = reject(file, (ele) => ele.phone === data.phone)
        setFile(removed)
    }

    const colFields = user.map((ele) => {
        if (ele.field === "action") {
            return {
                ...ele,
                renderCell: (params) => (
                    <IconButton onClick={() => handleCancel(params?.row)}>
                        <Cancel color="red" />
                    </IconButton>
                ),
            }
        }
        if (ele.field === "profile_picture") {
            return {
                ...ele,
                renderCell: (params) => (
                    <Avatar
                        sx={{
                            height: "100%",
                            maxHeight: "40px",
                            borderRadius: "50%",
                            objectFit: "contain",
                        }}
                        src={
                            `${
                                find(users?.data, (el) =>
                                    isPhone(el.phone, params.row.phone)
                                )?.profile_picture
                            }` || ""
                        }
                    />
                ),
            }
        }
        return ele
    })

    // if (isLoading)
    //     return (
    //         <Box>
    //             <CircularProgress />
    //         </Box>
    //     )
    if (isError) {
        return (
            <Alert
                severity="error"
                sx={{ py: 2, pl: 3 }}
                onClose={() => close()}
            >
                {error.response?.data?.error?.field_error?.map(
                    (err) =>
                        `${err.name} at line ${
                            err.description
                                ? Number(err.description.split(":")[0]) + 1
                                : null
                        } is invalid`
                )}
            </Alert>
        )
    }

    return (
        <Box
            sx={{
                marginTop: file ? "1em" : null,
                "& .white-header": {
                    backgroundColor: "white",
                },
            }}
        >
            <Box sx={{ height: "250px" }}>
                <DataGrid
                    columns={colFields}
                    rows={combinedArray.filter((el) => el.phone) || []}
                    hideFooter
                    disableColumnMenu
                    disableSelectionOnClick
                    getRowId={(params) => params.phone}
                    getRowHeight={({ id, densityFactor }) => {
                        if (id % 2 === 0) {
                            return 50 * densityFactor
                        }

                        return null
                    }}
                    sx={{
                        backgroundColor: "#fff",
                        boxShadow: 2,
                        border: 2,
                        borderColor: "primary.main",
                        "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                        },
                    }}
                />
            </Box>
            <Divider sx={{ my: "1em" }} />
            <Box
                sx={{
                    display: "flex",
                    gap: "1em",
                    pr: "2em",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => close()}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "primary.main" },
                        backgroundColor: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    startIcon={
                        adding ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    onClick={() => requestAdd(() => close())}
                >
                    Confirm
                </Button>
            </Box>
        </Box>
    )
}

export default AddBulkMember
