/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo } from "react"
import { Box, IconButton, Tooltip } from "@mui/material"
import { RemoveRedEye } from "@mui/icons-material"
import MaterialReactTable from "material-react-table"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import useTableState from "../../Hooks/useTableState.js"
import customeFilter from "../../Component/customFilterPanel.jsx"
import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useTableProps from "../../Hooks/useTableProps.js"

// List Receipts Component
function Receipts() {
    const { receiptsList } = useUserPermissions()
    const { md } = useBreakPoints()

    // HELPERS and EVENT HANDLERS
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <Link to={`${row.original.transaction_id}`}>
                    <Tooltip title="Get Receipt">
                        <IconButton size="small" sx={{ color: "primary.main" }}>
                            <RemoveRedEye />
                        </IconButton>
                    </Tooltip>
                </Link>
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />
    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 280,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customeFilter,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "receipt_number",
                header: "Invoice No.",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell.getValue()?.toString().padStart(8, "0"),
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 240,
                accessorKey: "company_name",
                header: "Company Name",
            },
            {
                size: 220,
                accessorKey: "phone",
                header: "Company Phone",
                Cell: ({ row }) => row?.original?.company_info?.phone,
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 220,
                accessorKey: "license_number",
                header: "Buyer's TIN",
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 200,
                accessorKey: "amount",
                header: "Amount",
                filterVariant: "number",
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 220,
                accessorKey: "service_charge",
                header: "Service Charge",
                filterVariant: "number",
                Cell: ({ row }) =>
                    formatToTwoDecimalPlaces(row?.original?.service_charge),
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 200,
                accessorKey: "vat",
                header: "Tax",
                filterVariant: "number",
                Cell: ({ row }) => formatToTwoDecimalPlaces(row?.original?.vat),
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 200,
                accessorKey: "type",
                header: "Type",
                renderColumnFilterModeMenuItems: customeFilter,
            },
            {
                size: 280,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customeFilter,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions]
    )
    const { params, state, onChangeHandlers } = useTableState({
        columns: columns,
    })
    // QUERY & MUTATION
    const { isLoading, isFetching, data, refetch } = useFetchEntitiesPerPage({
        endPoint: `receipt`,
        ...params,
    })
    const { props } = useTableProps({
        columns: columns,
        exportProps: {
            dataEndPoint: `receipt`,
            model: params.filterModel,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        refetch: refetch,
    })

    // RENDER
    if (!receiptsList) {
        return <RestrictedAccess />
    }
    return (
        <Box sx={{ height: "100%" }}>
            <MaterialReactTable
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        right: [md ? "action" : null],
                    },
                }}
                {...props}
                {...onChangeHandlers}
                muiTableContainerProps={{
                    sx: { maxHeight: "64vh" },
                }}
            />
        </Box>
    )
}

export default Receipts
