import React, { useCallback, useContext, useMemo, useState } from "react"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { Visibility } from "@mui/icons-material"
import { DateTime } from "luxon"

import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import ViewTripDetails from "../../Component/viewTripDetail.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import EmergencyTripFormDialog from "./emergencyTripForm.jsx"

import AddMembers from "./addMembers.jsx"
import StatusChanger from "./statusChanger.jsx"
import AuthContext from "../../Context/auth_provider.jsx"
import PaymentTypeSelector from "./paymentTypeSelector.jsx"

const emergencyTrips = [
    {
        accessorKey: "created_at",
        header: "Date",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "phone",
        header: "User Phone",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "start_location",
        header: "Start Location",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "end_location",
        header: "End Location",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "trip_type",
        header: "Trip Type",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) =>
            (row.original?.trip_type || row.original?.trip_type !== "") && (
                <Button
                    disableElevation
                    variant="contained"
                    color="common"
                    sx={{ py: ".5em", px: "1em", borderRadius: "1em" }}
                >
                    {row.original.trip_type}
                </Button>
            ),
    },
    {
        accessorKey: "payment_type",
        header: "Payment Type",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        filterVariant: "select",
        filterSelectOptions: ["AUTOMATIC", "MANUAL"],
    },
    {
        accessorKey: "payment_status",
        header: "Payment Status",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) =>
            (row.original?.payment_status ||
                row.original?.payment_status !== "") && (
                <Button
                    disableElevation
                    variant="contained"
                    color="common"
                    sx={{ py: ".5em", px: "1em", borderRadius: "1em" }}
                >
                    {row.original?.payment_status}
                </Button>
            ),
    },
    {
        accessorKey: "name",
        header: "Team",
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ row }) => (
            <Button
                disableElevation
                variant="contained"
                color="common"
                sx={{
                    px: "1em",
                    py: ".5em",
                    fontWeight: 900,
                    borderRadius: "1em",
                    textTransform: "none",
                }}
            >
                {row.original?.team?.name}
            </Button>
        ),
    },
    {
        accessorKey: "note",
        header: "Note",
        enableSorting: false,
        enableColumnFilter: false,
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "reason_failed",
        header: "Failure Reason",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "updated_at",
        header: "Updated At",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "action",
        header: "Actions",
        size: 260,
        enableSorting: false,
        enableColumnFilter: false,
    },
]

const userFields = [
    {
        accessorKey: "created_at",
        header: "Created At",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) =>
            `${DateTime.fromISO(cell.getValue()).toFormat("ff")}`,
    },
    {
        accessorKey: "first_name",
        header: "First Name",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "middle_name",
        header: "Middle Name",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "last_name",
        header: "Last Name",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "phone",
        header: "Phone No",
        size: 150,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    // {
    //     accessorKey: "status",
    //     header: "Status",
    //     size: 230,
    //     filterVariant: "select",
    //     filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
    //     renderColumnFilterModeMenuItems: customeFilter,
    //     valueOptions: ["ACTIVE", "INACTIVE", "PENDING"],
    // },
]
function EmergencyTrips() {
    const {
        addTripUsers,
        getTripUsers,
        createEmergencyTrip,
        getEmergencyTrips,
        emergencyTripsPage,
        updateEmergencyTrip,
    } = useUserPermissions()
    const [detail, setDetail] = useState(false)
    const [open, setOpen] = useState(false)
    const [addUser, setAddUser] = useState(false)
    const [tabName, setTabName] = useState("trips") // Emergency Trips list | Emergency trip users list
    const { teamId } = useContext(AuthContext)

    const { params, state, onChangeHandlers } = useTableState({
        columns: tabName === "trips" ? emergencyTrips : userFields,
    })
    const { filterModel, ...rest } = params
    let filterModelWithTab
    if (tabName === "users") {
        filterModelWithTab = [
            ...filterModel,
            {
                column_field: "is_program_users",
                operator_value: "is",
                value: "false",
            },
        ]
    } else {
        filterModelWithTab = filterModel
    }
    const requestParams = { filterModel: filterModelWithTab, ...rest }

    const handleEmergencyTripView = useMemo(
        () => (row) => {
            setDetail({ ...row })
        },
        []
    )
    const handleTabChange = (selectedTab) =>
        selectedTab !== "trips" ? setTabName("users") : setTabName("trips")

    const exportProcessor = (data) =>
        tabName === "trips"
            ? data?.map((el) => ({
                  created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
                  user_name: `${el.User?.first_name} ${el.User?.last_name} ${el.User?.last_name}`,
                  user_phone: el.phone ?? el.User?.phone,
                  start_location: el.start_location,
                  end_location: el.end_location,
                  note: el.note,
                  payment_type: el.payment_type,
                  status: el.status,
                  payment_status: el.payment_status,
                  reason_failed: el.reason_failed,
                  trip_type: el.reason_failed,
                  updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
              }))
            : data?.map((el) => ({
                  created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
                  updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
                  phone: el?.phone,
                  first_name: el?.first_name,
                  middle_name: el?.middle_name,
                  last_name: el?.last_name,
              }))

    const {
        data: emergencyTripsData,
        isLoading,
        isFetching,
        refetch,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "emergency_trips",
            ...params,
        },
        { enabled: Boolean(getEmergencyTrips && tabName === "trips") }
    )
    const {
        data: emergencyTripUsers,
        isFetching: fetchingUsers,
        refetch: refetchUsers,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "member",
            ...requestParams,
        },
        { enabled: Boolean(getTripUsers && tabName === "users") }
    )

    const paymentTypeColumn = useCallback(
        ({ cell, row }) => (
            <PaymentTypeSelector
                id={row.original.id}
                mode={cell.getValue()}
                refetch={refetch}
                disabled={
                    row.original.payment_status !== "PENDING" &&
                    row.original?.trip_id !==
                        "00000000-0000-0000-0000-000000000000"
                }
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    const actionColumn = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                {updateEmergencyTrip && (
                    <StatusChanger
                        id={row.original.id}
                        status={row.original.status}
                        refetch={refetch}
                    />
                )}
                {row.original.trip_id &&
                    row.original.trip_id !==
                        "00000000-0000-0000-0000-000000000000" && (
                        <Tooltip title="View Trip Details">
                            <IconButton
                                onClick={() =>
                                    handleEmergencyTripView(row.original)
                                }
                                sx={{ color: "primary.main" }}
                            >
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    )}
            </Box>
        ),
        [handleEmergencyTripView, refetch, updateEmergencyTrip]
    )
    const colDefs = useMemo(
        () =>
            tabName === "trips"
                ? emergencyTrips.map((el) => {
                      if (el.accessorKey === "action") {
                          return {
                              ...el,
                              Cell: actionColumn,
                          }
                      }
                      if (el.accessorKey === "payment_type") {
                          return {
                              ...el,
                              Cell: paymentTypeColumn,
                          }
                      }
                      return el
                  })
                : userFields,
        [actionColumn, paymentTypeColumn, tabName]
    )

    const { props } = useTableProps({
        columns: colDefs,
        actionButtonProps:
            tabName === "trips"
                ? createEmergencyTrip && {
                      actionName: "Order pre-RIDE Trip",
                      onClick: () => setOpen(true),
                  }
                : addTripUsers && {
                      actionName: "Add User",
                      onClick: () => setAddUser(true),
                  },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching || fetchingUsers,
            columnVisibility: {
                name: !teamId,
            },
        },
        exportProps: {
            dataEndPoint: tabName === "trips" ? `emergency_trips` : `member`,
            model: filterModelWithTab,
            formatter: exportProcessor,
        },
        tabProps: teamId && {
            tabs: [
                { value: "trips", label: "pre-RIDE" },
                { value: "users", label: "Users" },
            ],
            onChange: handleTabChange,
        },
        refetch: tabName === "trips" ? refetch : refetchUsers,
    })

    if (!emergencyTripsPage) return <RestrictedAccess />

    return (
        <Box>
            {open && (
                <EmergencyTripFormDialog
                    open={open}
                    setOpen={setOpen}
                    refetch={refetch}
                />
            )}
            {addUser && (
                <AddMembers
                    open={addUser}
                    setOpen={setAddUser}
                    refetch={refetchUsers}
                />
            )}
            <ViewTripDetails detail={detail} setDetail={setDetail} />
            <Box sx={{ backgroundColor: "white" }}>
                <MaterialReactTable
                    data={
                        tabName === "trips"
                            ? emergencyTripsData?.data?.data ?? []
                            : emergencyTripUsers?.data?.data?.map((el) => ({
                                  created_at: el?.created_at,
                                  ...el?.user,
                              })) ?? []
                    }
                    rowCount={
                        tabName === "trips"
                            ? emergencyTripsData?.data?.meta_data?.total ?? 0
                            : emergencyTripUsers?.data?.data?.meta_data
                                  ?.total ?? 0
                    }
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default EmergencyTrips
