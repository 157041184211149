import React, { useCallback, useContext, useMemo, useState } from "react"
import { Box, Button, IconButton } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { Visibility } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import {
    formatToTwoDecimalPlaces,
    formatToThreeDecimalPlaces,
} from "../../Utils/dataFormat.js"

import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import ViewTripDetails from "../../Component/viewTripDetail.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import GenerateTransactionsReport from "./generateReport.jsx"
import AuthContext from "../../Context/auth_provider.jsx"

const transactions = [
    {
        accessorKey: "passenger_name",
        header: "Name",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "passenger_phone",
        header: "Phone",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "driver_name",
        header: "Driver Name",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "driver_phone",
        header: "Driver Phone",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        size: 200,
        accessorKey: "distance",
        header: "Distance",
        filterVariant: "number",
        align: "center",
        headerAlign: "center",
        Cell: ({ row }) =>
            // eslint-disable-next-line no-nested-ternary
            row?.original?.trip_type === "Ride Trip"
                ? `${formatToThreeDecimalPlaces(
                      Number(row?.original?.distance)
                  )} km`
                : Number(row?.original?.distance) > 1000
                ? `${formatToThreeDecimalPlaces(
                      Number(row?.original?.distance) / 1000
                  )} km`
                : `${formatToTwoDecimalPlaces(
                      Number(row?.original?.distance)
                  )} m`,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "amount",
        header: "Amount",
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) =>
            formatToTwoDecimalPlaces(Number(row.original?.amount)),
    },
    {
        accessorKey: "tip",
        header: "Tip",
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) => formatToTwoDecimalPlaces(Number(row.original?.tip)),
    },
    {
        accessorKey: "pickup_name",
        header: "Pickup Location",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "dropoff_name",
        header: "Dropoff Location",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "pickup_time",
        header: "Pickup Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue())?.toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "dropoff_time",
        header: "Dropoff Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue())?.toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "program_name",
        header: "Program",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
        Cell: ({ row }) => (
            <Button
                disableElevation
                variant="contained"
                color="common"
                sx={{ py: ".5em", px: ".5em", borderRadius: "1em" }}
            >
                {row.original?.program_name}
            </Button>
        ),
    },
    {
        accessorKey: "created_at",
        header: "Date",
        size: 200,
        filterVariant: "date",
        enableSorting: false,
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "view",
        header: " ",
        size: 200,
    },
]

function CompanyTransactions() {
    const {
        transactionsList,
        transactionsPage,
        transactionsView,
        teamTransactionsReport,
    } = useUserPermissions()
    const { teamId } = useContext(AuthContext)
    const { tId } = useParams()
    const endPoint = tId
        ? `teams/${tId}/program_transactions`
        : "program_transactions"
    const { params, state, onChangeHandlers } = useTableState({
        columns: transactions,
    })
    const [details, setDetail] = useState(false)
    const [open, setOpen] = useState(false)

    const handleTransactionView = useMemo(
        () => (row) => {
            setDetail({ ...row })
        },
        []
    )
    const handleDistance = (distance) =>
        distance > 1000
            ? `${formatToThreeDecimalPlaces(Number(distance) / 1000)} km`
            : `${formatToTwoDecimalPlaces(Number(distance))} m`

    const exportProcessor = (data) =>
        data?.map((el) => ({
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            driver_name: el.detail?.driver_name,
            driver_phone: el.detail?.driver_phone,
            received_from: el.detail?.passenger_name,
            user_phone: el.detail?.passenger_phone,
            amount: formatToTwoDecimalPlaces(Number(el.detail?.amount ?? 0)),
            distance:
                el?.detail?.trip_type === "Ride Trip"
                    ? `${formatToThreeDecimalPlaces(
                          Number(el?.detail?.distance)
                      )} km`
                    : handleDistance(el?.detail?.distance),
            tip: el.detail?.tip,
            pickup_name: el.detail?.pickup_name,
            dropoff_name: el.detail?.dropoff_name,
            pickup_time: DateTime.fromISO(el.detail?.pickup_time).toFormat(
                "ff"
            ),
            dropoff_time: DateTime.fromISO(el.detail?.dropoff_time).toFormat(
                "ff"
            ),
            program: el.detail?.program_name,
            note: el.note,
        }))

    const {
        data: Tdata,
        isLoading,
        isFetching,
        refetch,
    } = useFetchEntitiesPerPage(
        {
            endPoint: endPoint,
            ...params,
        },
        { enabled: transactionsList }
    )

    const actionColumn = useCallback(
        ({ row }) => (
            <IconButton
                disabled={!transactionsView}
                onClick={() => handleTransactionView(row.original)}
                sx={{ color: "primary.main" }}
            >
                <Visibility />
            </IconButton>
        ),
        [handleTransactionView, transactionsView]
    )
    const colDefs = useMemo(
        () =>
            transactions.map((el) => {
                if (el.accessorKey === "view") {
                    return {
                        ...el,
                        Cell: actionColumn,
                    }
                }
                return el
            }),
        [actionColumn]
    )
    // onClick={() => setOpen(true)}
    const { props } = useTableProps({
        columns: colDefs,
        actionButtonProps: (tId || teamId) &&
            teamTransactionsReport && {
                actionName: "Generate Report",
                onClick: () => setOpen(true),
            },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        exportProps: {
            dataEndPoint: `program_transactions`,
            model: params.filterModel,
            formatter: exportProcessor,
        },
        refetch: refetch,
    })

    if (!transactionsPage) return <RestrictedAccess />

    return (
        <Box sx={{ backgroundColor: "white" }}>
            {open && (
                <GenerateTransactionsReport open={open} setOpen={setOpen} />
            )}
            {transactionsView && (
                <ViewTripDetails detail={details} setDetail={setDetail} />
            )}
            <MaterialReactTable
                data={
                    Tdata?.data?.data?.map((el) => ({
                        created_at: el.created_at,
                        note: el.note,
                        ...el.detail,
                    })) ?? []
                }
                rowCount={Tdata?.data?.meta_data?.total ?? 0}
                initialState={{
                    columnPinning: { left: ["name"], right: ["status"] },
                }}
                {...props}
                {...onChangeHandlers}
            />
        </Box>
    )
}

export default CompanyTransactions
