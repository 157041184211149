import { useContext } from "react"

import AuthContext from "../Context/auth_provider.jsx"
import { CompanyInfo, TeamInfo } from "../Pages/SystemUsers/companyList.jsx"

const useUserPermissions = () => {
    const { teamId } = useContext(AuthContext)
    const { userPermissions } = useContext(CompanyInfo)
    const { userPermissions: teamPermissions, teamDomainPermissionsList } =
        useContext(TeamInfo)

    const isTeamDomainPermission = (permission) =>
        teamDomainPermissionsList?.includes(permission)

    const perms = teamId
        ? teamPermissions?.map((el) => el.name)
        : userPermissions?.map((el) => el.name)

    // Permission checking function
    const hasPermission = (permission) => {
        if (teamId) {
            // In team context, only return permissions that exist in team domain
            if (isTeamDomainPermission(permission)) {
                return perms?.some(
                    (el) => el === permission || el === "manage-all"
                )
            }
            return false
        }
        // In company context, check company permissions
        return perms?.some((el) => el === permission || el === "manage-all")
    }

    /* Permissions List */
    const permissions = {
        // Programs
        programsUsersList: hasPermission("list program users"),
        programsTransactions: hasPermission("view programs transaction"),
        programsCreate: hasPermission("create program"),
        programsDetail: hasPermission("view programs detail"),
        programsUpdate: hasPermission("update program"),
        programsList: hasPermission("list programs"),

        // Users
        usersInvite: hasPermission("invite users"),
        usersAssign: hasPermission("assign program to users"),
        usersAssignSingle: hasPermission("get user by phone"),
        usersInvitedProgramsUpdate: hasPermission(
            "update corporate program invited users"
        ),
        usersAssignBulk: hasPermission("get users by phone"),
        usersListInvited: hasPermission("list invited users"),
        usersList: hasPermission("list users"),
        usersProgramsUpdate: hasPermission("update assigned programs"),

        // Transactions
        transactionsList: hasPermission("list all transactions"),
        transactionsView: hasPermission("view street pickup details"),

        // Config
        configUpdate: hasPermission("update tip config"),

        // System Users
        systemUsersList: hasPermission("get system users"),
        systemUsersRevokeRole: hasPermission("revoke role"),
        systemUsersView: hasPermission(""),
        systemUsersAdd: hasPermission("assign role"),

        // Roles
        rolesUpdate: hasPermission("update role"),
        rolesList: hasPermission("get all roles"),
        rolesCreate: hasPermission("create role"),
        rolesDelete: hasPermission("delete role"),
        rolesDetail: hasPermission("get role detail"),
        rolesStatusUpdate: hasPermission("update user's role status"),

        // Invoice
        invoiceList: hasPermission("list invoices"),

        // Other features
        topupsList: hasPermission("get pre paid top ups"),
        seeBalance: hasPermission("get account balance"),
        activitiesList: hasPermission("list corporate activities"),
        getAllPermissions: hasPermission("get all permissions"),

        // Voucher
        generateVoucher: hasPermission("voucher"),
        vouchersList: hasPermission("view voucher"),
        vouchersUpdate: hasPermission("update-voucher"),
        voucherUsers: hasPermission("view voucher users"),

        // API Keys
        apiKeysList: hasPermission("list all corporate api keys"),
        generateApiKey: hasPermission("create corporate api key"),
        apiKeyDelete: hasPermission("delete corporate api key"),

        // Discounts
        generateDiscount: hasPermission("generate discount code"),
        discountsList: hasPermission("get discount all discount codes"),
        discountsUpdate: hasPermission("update-discount"),
        discountUsers: hasPermission("view discount users"),

        // Receipts
        receiptsList: hasPermission("get corporate receipts"),
        receiptsDetails: hasPermission("get receipt by transaction ID"),

        // Emergency Trips
        getEmergencyTrips: hasPermission("get emergency trip"),
        createEmergencyTrip: hasPermission("create emergency trip"),
        approveEmergencyTrips: hasPermission("approve emergency trip"),
        updateEmergencyTrip: hasPermission("update emergency trip"),
        getTripUsers: hasPermission("get all team users"),
        addTripUsers: hasPermission("upload users to team"),

        // Teams
        teamsList: hasPermission("list company team"),
        userTeams: hasPermission(`list company user's teams`),
        teamsCreate: hasPermission("create team"),
        teamUserDetails: hasPermission("get user by phone"),
        assignTeamAdmin: hasPermission("assign  team admin role"),
        teamSystemUsersList: hasPermission("get team system users"),
        updateTeamAdminRoleStatus: hasPermission("change team admin role status"),
        teamTransactionsList: hasPermission(`list team program(s) transactions`),
        teamMembersList: hasPermission("list team users"),
        teamProgramsList: hasPermission("list team program"),
        teamTransactionsReport: hasPermission("view team transactions report"),

        // Page access getters
        get programsPage() {
            return (
                this.programsUsersList ||
                this.programsCreate ||
                this.programsDetail ||
                this.programsUpdate
            )
        },

        get invoicePage() {
            return this.invoiceList || this.invoiceView
        },

        get usersPage() {
            return (
                this.usersInvite ||
                this.usersListInvited ||
                this.usersAssign ||
                this.usersProgramsUpdate ||
                this.usersList
            )
        },

        get transactionsPage() {
            return this.transactionsList || this.transactionsView
        },

        get systemUsersPage() {
            return (
                this.systemUsersList ||
                this.systemUsersRevokeRole ||
                this.systemUsersAdd
            )
        },

        get rolesPage() {
            return (
                this.rolesCreate ||
                this.rolesDelete ||
                this.rolesList ||
                this.rolesDetail ||
                this.rolesStatusUpdate ||
                this.rolesUpdate
            )
        },

        get configPage() {
            return this.configUpdate
        },

        get voucherPage() {
            return (
                this.generateVoucher ||
                this.vouchersList ||
                this.vouchersUpdate ||
                this.voucherUsers
            )
        },

        get apiKeyPage() {
            return this.generateApiKey || this.apiKeysList || this.apiKeyDelete
        },

        get discountPage() {
            return (
                this.generateDiscount ||
                this.discountsList ||
                this.discountsUpdate ||
                this.discountUsers
            )
        },

        get receiptPage() {
            return this.receiptsList || this.receiptsDetails
        },

        get emergencyTripsPage() {
            return (
                this.getEmergencyTrips ||
                this.createEmergencyTrip ||
                this.approveEmergencyTrips ||
                this.updateEmergencyTrip ||
                this.getTripUsers ||
                this.addTripUsers
            )
        },

        get teamsPage() {
            return (
                this.teamsList ||
                this.teamsCreate ||
                this.userTeams ||
                this.assignTeamAdmin ||
                this.teamMembersList ||
                this.teamProgramsList ||
                this.teamSystemUsersList ||
                this.teamTransactions ||
                this.updateTeamAdminRoleStatus
            )
        },
    }

    return permissions
}

export default useUserPermissions
