import React, { useCallback, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Box, IconButton } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { DateTime } from "luxon"
import { Visibility } from "@mui/icons-material"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"
import customeFilter from "../../Component/customFilterPanel.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import useTableProps from "../../Hooks/useTableProps.js"
import useTableState from "../../Hooks/useTableState.js"
import ViewTripDetails from "../../Component/viewTripDetail.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

const transactions = [
    {
        accessorKey: "passenger_name",
        header: "Name",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "passenger_phone",
        header: "Phone",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "driver_name",
        header: "Driver Name",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "driver_phone",
        header: "Driver Phone",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "amount",
        header: "Amount",
        filterVariant: "number",
        size: 200,
        Cell: ({ row }) =>
            formatToTwoDecimalPlaces(Number(row.original?.amount)),
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "tip",
        header: "Tip",
        filterVariant: "number",
        size: 200,
        Cell: ({ row }) => formatToTwoDecimalPlaces(Number(row.original?.tip)),
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "pickup_name",
        header: "Pickup Location",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "dropoff_name",
        header: "Dropoff Location",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "pickup_time",
        header: "Pickup Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue())?.toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "dropoff_time",
        header: "Dropoff Time",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue())?.toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "created_at",
        header: "Date",
        filterVariant: "date",
        sortable: false,
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
        muiTableHeadCellFilterTextFieldProps: ({ column }) => ({
            type: "date",
            onChange: (event) => {
                column?.setFilterValue(event?.target?.value)
            },
        }),
        Cell: ({ row }) =>
            `${DateTime.fromISO(row.original?.created_at).toFormat("ff")}`,
    },
    {
        accessorKey: "view",
        header: " ",
        size: 200,
    },
]

function ProgramTransactions() {
    const { programsTransactions } = useUserPermissions()
    const {
        params,
        state: tableState,
        onChangeHandlers,
    } = useTableState({
        columns: transactions,
    })
    const [detail, setDetail] = useState(null)
    // SPECIFIC PROGRAM ID, COMES FROM THE PROGRAMS TABLE
    const { state } = useLocation()
    const programId = state.id
    const programName = state.name

    const handleView = useMemo(
        () => (row) => {
            setDetail({ ...row, name: programName })
        },
        [programName]
    )

    const exportProcessor = (data) =>
        data?.map((el) => ({
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            driver_name: el.detail?.driver_name,
            driver_phone: el.detail?.driver_phone,
            received_from: el.detail?.passenger_name,
            user_phone: el.detail?.passenger_phone,
            amount: el.detail?.amount,
            tip: el.detail?.tip,
            pickup_name: el.detail?.pickup_name,
            dropoff_name: el.detail?.dropoff_name,
            pickup_time: DateTime.fromISO(el.detail?.pickup_time).toFormat(
                "ff"
            ),
            dropoff_time: DateTime.fromISO(el.detail?.dropoff_time).toFormat(
                "ff"
            ),
            program: el.detail?.program_name,
            note: el.note,
        }))

    const { data, isLoading, isFetching, refetch } = useFetchEntitiesPerPage({
        endPoint: `programs/${programId}/transactions`,
        ...params,
    })

    const actionColumn = useCallback(
        ({ row }) => (
            <IconButton onClick={() => handleView(row.original)}>
                <Visibility />
            </IconButton>
        ),
        [handleView]
    )

    const customDateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )

    const colDefs = useMemo(
        () =>
            transactions.map((el) => {
                if (el.accessorKey === "created_at")
                    return {
                        ...el,
                        Filter: customDateFilter,
                    }
                if (el.accessorKey === "view")
                    return {
                        ...el,
                        Cell: actionColumn,
                    }
                return el
            }),
        [actionColumn, customDateFilter]
    )

    const { props } = useTableProps({
        columns: colDefs,
        exportProps: {
            dataEndPoint: `programs/${programId}/transactions`,
            model: params.filterModel,
            formatter: exportProcessor,
        },
        state: {
            ...tableState,
            showSkeleton: isLoading,
            showProgressBars: isFetching,
        },
        refetch: refetch,
    })

    if (!programsTransactions) return <RestrictedAccess />

    return (
        <Box sx={{ backgroundColor: "white" }}>
            <ViewTripDetails detail={detail} setDetail={setDetail} />
            <MaterialReactTable
                data={
                    data?.data?.data?.map((el) => ({
                        created_at: el.created_at,
                        note: el.note,
                        ...el.detail,
                    })) ?? []
                }
                rowCount={data?.data?.meta_data?.total ?? 0}
                initialState={{
                    showColumnFilters: true,
                    columnPinning: { right: ["status"] },
                }}
                {...onChangeHandlers}
                {...props}
            />
        </Box>
    )
}

export default ProgramTransactions
