import React from "react"
import { useForm, Controller } from "react-hook-form"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Grid,
    Typography,
    CircularProgress,
} from "@mui/material"
import { useSnackbar } from "notistack"
import useCreateEntity from "../../Hooks/useCreateEntity.js"

function CreateTeamForm({ open, setOpen, refetch }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            name: "",
            description: "",
        },
        mode: "onChange",
    })

    const { enqueueSnackbar } = useSnackbar()
    const { mutate, isLoading } = useCreateEntity("teams")

    const handleClose = () => {
        setOpen(false)
        reset()
    }

    const onSubmit = (data) => {
        mutate(data, {
            onSuccess: () => {
                enqueueSnackbar("Department created successfully", {
                    variant: "success",
                })
                handleClose()
                refetch()
            },
            onError: (error) => {
                enqueueSnackbar(
                    error?.message || "Failed to create department",
                    {
                        variant: "error",
                    }
                )
            },
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                    mb: 2,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    Create Department
                </Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Department Name"
                                        fullWidth
                                        margin="dense"
                                        error={Boolean(errors.name)}
                                        helperText={errors.name?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Description"
                                        multiline
                                        fullWidth
                                        margin="dense"
                                        error={Boolean(errors.description)}
                                        helperText={errors.description?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                sx={{
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        color: "primary.main",
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "primary.main",
                        ":hover": { bgcolor: "primary.main" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    disabled={isLoading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Add
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "common.main",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateTeamForm
