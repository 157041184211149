import React, { useState } from "react"
import {
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"

import usePartialUpdateEntity from "../../Hooks/usePartialUpdateEntity.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"

function PaymentTypeSelector({ id, mode, refetch, disabled }) {
    const { updateEmergencyTrip } = useUserPermissions()
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null)

    const { enqueueSnackbar } = useSnackbar()

    const { mutate, isLoading } = usePartialUpdateEntity(`emergency_trip`)

    const handleChange = (event) => {
        setSelectedValue(event.target.value)
        setOpenDialog(true)
    }

    const handleDialogClose = (confirm) => {
        if (confirm && selectedValue) {
            mutate(
                { id: id, payment_type: selectedValue },
                {
                    onError: (error) => {
                        enqueueSnackbar(
                            `Update failed due to ${error.message}`,
                            { variant: "error" }
                        )
                        setOpenDialog(false)
                    },
                    onSuccess: () => {
                        enqueueSnackbar("Payment type updated successfully", {
                            variant: "success",
                        })
                        setOpenDialog(false)
                        refetch()
                    },
                }
            )
        }
    }

    return (
        <div>
            <FormControl fullWidth>
                <Select
                    labelId="mode-select-label"
                    value={mode}
                    onChange={handleChange}
                    size="small"
                    disabled={!updateEmergencyTrip || disabled}
                >
                    <MenuItem value="AUTOMATIC">AUTOMATIC</MenuItem>
                    <MenuItem value="MANUAL">MANUAL</MenuItem>
                </Select>
            </FormControl>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                        mb: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Confirm Change
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ my: "1em" }}>
                    <DialogContentText>
                        Are you sure you want to change the payment type to{" "}
                        <strong>{selectedValue}</strong>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        pr: 3,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            color: "primary.main",
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            bgcolor: "primary.main",
                            ":hover": { bgcolor: "primary.main" },
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                        onClick={() => handleDialogClose(true)}
                    >
                        Confirm
                        {isLoading && (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: "common.main",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PaymentTypeSelector
