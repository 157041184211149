import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import AuthContext from "../Context/auth_provider.jsx"
import useAxiosPrivate from "./useAxiosPrivate.js"

// Create/Add an Entity Request Hook
const useCreateEntity = (page) => {
    const queryClient = useQueryClient()
    const axiosPrivate = useAxiosPrivate()
    const { companyId, teamId } = useContext(AuthContext)
    const domain = teamId ? `/team/${teamId}` : `/corporate/${companyId}`

    return useMutation(
        (data) =>
            axiosPrivate
                .post(`${domain}/${page}`, data)
                .then((response) => response),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([page])
            },
        }
    )
}

export default useCreateEntity