/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react"
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import {
    AttachMoney,
    CalendarToday,
    Close,
    DirectionsCar,
} from "@mui/icons-material"
import { DateTime } from "luxon"
import { Box } from "@mui/system"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"

import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import CustomDateTimePicker from "../../Component/cutomDateTimePicker.jsx"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

function TransactionReport({ amount, count, fromDate, toDate }) {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ p: "1rem", width: "100%" }}
        >
            <Card
                sx={{
                    p: "2rem",
                    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                    borderRadius: "16px",
                    width: "100%",
                }}
            >
                <CardContent>
                    {/* Transaction Date Section */}
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mb: "1.5rem" }}
                    >
                        <Avatar
                            sx={{
                                mr: "1rem",
                                backgroundColor: "#ff9800",
                                color: "#fff",
                            }}
                        >
                            <CalendarToday />
                        </Avatar>
                        <Box textAlign="center">
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    color: "#ff9800",
                                }}
                            >
                                Transaction Period
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "#6b7280",
                                    textAlign: "start",
                                }}
                            >
                                From:{" "}
                                <strong>
                                    {DateTime.fromISO(fromDate).toLocaleString(
                                        DateTime.DATETIME_MED
                                    )}
                                </strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "#6b7280",
                                    textAlign: "start",
                                }}
                            >
                                To:{" "}
                                <strong>
                                    {DateTime.fromISO(toDate).toLocaleString(
                                        DateTime.DATETIME_MED
                                    )}
                                </strong>
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ mb: "1.5rem" }} />

                    {/* Money Spent Section */}
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            mb: "1rem",
                            background: "#f1f8e9",
                            p: "0.75rem 1rem",
                            borderRadius: "12px",
                        }}
                    >
                        <Avatar
                            sx={{
                                mr: "1rem",
                                backgroundColor: "#4caf50",
                                color: "#fff",
                            }}
                        >
                            <AttachMoney />
                        </Avatar>
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "1rem", fontWeight: 600 }}
                            >
                                Money Spent
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: "1.25rem", fontWeight: 700 }}
                            >
                                {`ETB ${formatToTwoDecimalPlaces(amount)}`}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Number of Trips Section */}
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            background: "#e3f2fd",
                            p: "0.75rem 1rem",
                            borderRadius: "12px",
                        }}
                    >
                        <Avatar
                            sx={{
                                mr: "1rem",
                                backgroundColor: "#2196f3",
                                color: "#fff",
                            }}
                        >
                            <DirectionsCar />
                        </Avatar>
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{ fontSize: "1rem", fontWeight: 600 }}
                            >
                                Number of Trips
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: "1.25rem", fontWeight: 700 }}
                            >
                                {count?.toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

// Transaction report generating dialog component
function GenerateTransactionsReport({ open, setOpen }) {
    const { tId } = useParams()
    const endPoint = tId ? `teams/${tId}/report` : `report`

    // USESTATE & USEREF
    const [openGenerated, setOpenGenerated] = useState(false)
    const [fromDate, setFrom] = useState(null)
    const [toDate, setTo] = useState(null)
    const [report, setReport] = useState(null)
    const componentRef = useRef()

    // QUERY & MUTATION
    const { data, isFetching, refetch } = useFetchEntitiesPerPage(
        {
            endPoint: endPoint,
            filterModel:
                !!fromDate &&
                !!toDate &&
                ([
                    {
                        column_field: "created_at",
                        operator_value: "between",
                        value: `${fromDate}|${toDate}`,
                    },
                ] ??
                    undefined),
        },
        { enabled: !!fromDate && !!toDate && openGenerated }
    )

    // EVENT HANDLERS & HELPERS
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
        documentTitle:
            fromDate?.length > 10 && toDate?.length > 10
                ? `Transaction report  from ${DateTime.fromISO(
                      fromDate
                  ).toLocaleString(
                      DateTime.DATETIME_MED
                  )} to ${DateTime.fromISO(toDate).toLocaleString(
                      DateTime.DATETIME_MED
                  )}`
                : "",
    })

    // USEEFFECTS
    useEffect(() => {
        if (data && openGenerated && !isFetching) {
            setReport(data?.data?.data)
        }

        if (!open) {
            setFrom(null)
            setTo(null)
        }
    }, [data, isFetching, open, openGenerated])

    useEffect(() => {
        setFrom(null)
        setTo(null)
    }, [])

    useEffect(() => {
        if (!!fromDate && !!toDate && openGenerated) {
            refetch()
        }
    }, [fromDate, toDate, openGenerated, refetch])

    // RENDER
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 3,
                    boxShadow: "0px 1px 7px #0000001A",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid container>
                    <Grid xs={10} lg={10} sm={10} xl={11} md={11}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                            {isFetching && openGenerated && "Generating..."}
                            {report !== null &&
                                openGenerated &&
                                "Transaction Report"}
                            {report === null &&
                                !openGenerated &&
                                "Generate Report"}
                        </Typography>
                    </Grid>
                    <Grid sx={{ ml: report !== null && openGenerated ? 1 : 0 }}>
                        {report ? (
                            <Tooltip title="Close Modal" arrow enterDelay={50}>
                                <IconButton
                                    onClick={() => {
                                        setOpen(false)
                                        setOpenGenerated(false)
                                        setReport(null)
                                    }}
                                    sx={{ color: "#000" }}
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    my: 1,
                }}
                ref={componentRef}
            >
                {isFetching ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress
                            sx={{
                                m: 5,
                                color: "#000000",
                            }}
                        />
                    </Box>
                ) : report !== null && openGenerated ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                            mt: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                mb: 2,
                                py: 2,
                                width: "100%",
                            }}
                        >
                            <TransactionReport
                                amount={report?.total_amount}
                                count={report?.total_count}
                                fromDate={fromDate}
                                toDate={toDate}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ width: "100%" }}>
                        <Typography
                            sx={{
                                fontSize: "17px",
                                mt: "1em",
                            }}
                        >
                            Choose date and generate report
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                mb: 4,
                                mt: 3,
                            }}
                        >
                            <CustomDateTimePicker
                                label="From"
                                value={fromDate}
                                maxDate={toDate}
                                onChange={(newValue) => {
                                    setFrom(newValue)
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <CustomDateTimePicker
                                disableFuture
                                label="To"
                                value={toDate}
                                minDate={fromDate}
                                onChange={(newValue) => {
                                    setTo(newValue)
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            {report && openGenerated ? (
                <DialogActions
                    sx={{
                        backgroundColor: "#FAFAFA",
                        py: 3,
                        px: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        boxShadow: "0px 1px 7px #0000001A",
                        borderRadius: "6px",
                    }}
                >
                    <Button
                        onClick={() => {
                            setOpenGenerated(false)
                            setReport(null)
                        }}
                        size="small"
                        variant="contained"
                        sx={{
                            color: "primary.main",
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                    >
                        back
                    </Button>
                    <Box sx={{ px: 1 }} />
                    <Button
                        disabled={isFetching}
                        variant="contained"
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            color: "#fff",
                            boxShadow: " 0px 2px 5px #00000033",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions
                    sx={{
                        backgroundColor: "#FAFAFA",
                        py: 3,
                        px: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        boxShadow: "0px 1px 7px #0000001A",
                        borderRadius: "6px",
                    }}
                >
                    <Button
                        onClick={() => {
                            setOpen(false)
                            setReport(null)
                        }}
                        size="small"
                        variant="contained"
                        sx={{
                            color: "primary.main",
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ px: 1 }} />
                    <Button
                        disabled={isFetching}
                        variant="contained"
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            color: "#fff",
                            boxShadow: " 0px 2px 5px #00000033",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={() => {
                            setOpenGenerated(true)
                        }}
                    >
                        Generate
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}
export default GenerateTransactionsReport
