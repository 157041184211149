import { useContext } from "react"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"

import useAxiosPrivate from "./useAxiosPrivate.js"
import AuthContext from "../Context/auth_provider.jsx"

// Fetch/Get an Entity Details Hook
const useFetchEntities = (endPoint, options, dependency) => {
    const axiosPrivate = useAxiosPrivate()
    const { companyId, teamId } = useContext(AuthContext)
    const domain = teamId ? `/team/${teamId}` : `/corporate/${companyId}`

    const { enqueueSnackbar } = useSnackbar()
    return useQuery({
        queryKey: [
            endPoint,
            domain, dependency],
        queryFn: () =>
            axiosPrivate
                .get(`${domain}/${endPoint}`, {
                    params: {
                        page: 0,
                        per_page: -1,
                    },
                })
                .then((response) => response),

        onError: (error) => {
            const fieldError =
                error.response?.data?.error?.field_error?.[0]?.description
            const errorMessage = error.response?.data?.error?.message
            enqueueSnackbar(fieldError || errorMessage || "Request Failed", {
                variant: "error",
            })
        },
        ...options,
    })
}

export default useFetchEntities
