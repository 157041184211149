import React, { useEffect } from "react"
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"

import useCreateEntity from "../../Hooks/useCreateEntity.js"
import useSSOUsers from "../../Hooks/useSSOUsers.js"

function Member({ name, phone, src }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f7f7f7",
                py: "1em",
                px: "1em",
            }}
        >
            <Avatar
                style={{
                    height: "2em",
                    width: "2em",
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
                src={src || " "}
            />
            <Typography sx={{ fontSize: "1em", textAlign: "center" }}>
                {name}
            </Typography>
            <Typography sx={{ fontSize: "1em" }}>+{phone}</Typography>
        </Box>
    )
}

function AddSingleUser({ search, close }) {
    const { enqueueSnackbar } = useSnackbar()

    const { data: user, mutate, isLoading, isError } = useSSOUsers()

    useEffect(() => mutate({ phones: [search] }), [search, mutate])
    const { mutate: addMember, isLoading: assigning } =
        useCreateEntity("users/seed")

    const handleAdd = () => {
        addMember(
            {
                users_id: [user?.data[0]?.id],
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Successfully Added", {
                        variant: "success",
                    })
                    close()
                },
                onError: (error) => {
                    const fieldError =
                        error.response?.data?.error?.field_error?.[0]
                            ?.description
                    const errorMessage = error.response?.data?.error?.message
                    enqueueSnackbar(
                        fieldError ||
                            errorMessage ||
                            "Couldn't add the user. Try again later please",
                        { variant: "error" }
                    )
                },
            }
        )
    }

    if (isLoading)
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        )
    if (isError)
        return (
            <Alert
                severity="error"
                sx={{ py: 2, pl: 3 }}
                onClose={() => close()}
            >
                The provided phone does not exist, please try again.
            </Alert>
        )

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Box>
                {user?.data ? (
                    <Member
                        name={`${user?.data[0]?.first_name} ${user?.data[0]?.middle_name} ${user?.data[0]?.last_name}`}
                        phone={user?.data[0]?.phone}
                        src={user?.data[0]?.profile_picture}
                    />
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1em",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Alert severity="warning" sx={{ p: 2 }}>
                            A user with the provided phone does not exist in
                            RIDE Plus.
                        </Alert>
                    </Box>
                )}
            </Box>
            <Divider sx={{ my: "1em" }} />
            <Box
                sx={{
                    display: "flex",
                    gap: "1em",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        color: "black",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => close()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "primary.main" },
                        backgroundColor: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    startIcon={
                        assigning ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    onClick={() => handleAdd()}
                >
                    Add
                </Button>
            </Box>
        </Box>
    )
}

export default AddSingleUser
