export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BMS_BASE_URL = process.env.REACT_APP_BMS_BASE_URL
export const SSO_BASE_URL = process.env.REACT_APP_SSO_BASE_URL
export const SSO_API_BASE_URL = process.env.REACT_APP_SSO_API_BASE_URL
export const MAP_API_URL = process.env.REACT_APP_MAP_API_URL
export const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY
export const RP_ASSETS_URL = "assets"
export const SSO_ASSETS_URL = "assets/profile_picture"
export const VERSION = "v2"
export const SSO_VERSION = "v1"
