import React, { useMemo, useCallback, useState, useContext } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import { HighlightOff, TaskAlt } from "@mui/icons-material"
import { useLocation, useParams } from "react-router-dom"
import MaterialReactTable from "material-react-table"
import { useMutation } from "react-query"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"

import useTableProps from "../../Hooks/useTableProps.js"
import useTableState from "../../Hooks/useTableState.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import AddTeamAdmin from "./addTeamAdmin.jsx"
import AuthContext from "../../Context/auth_provider.jsx"
import StatusButton from "../../Component/statusButton.jsx"
import customeFilter from "../../Component/customFilterPanel.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"

const userFields = [
    {
        accessorKey: "created_at",
        header: "Created At",
        size: 200,
        filterVariant: "date",
        Cell: ({ cell }) =>
            `${DateTime.fromISO(cell.getValue()).toFormat("ff")}`,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "phone",
        header: "Phone",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "first_name",
        header: "Name",
        size: 200,
        Cell: ({ row }) =>
            `${row.original.first_name} ${row.original.middle_name}`,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "email",
        header: "Email",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorFn: (row) => row?.roles,
        id: "role_name",
        header: "Role",
        size: 200,
        renderColumnFilterModeMenuItems: customeFilter,
    },
    {
        accessorKey: "status",
        header: "Status",
        size: 200,
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        renderColumnFilterModeMenuItems: customeFilter,
    },
]

function TeamSystemUsers() {
    const {
        assignTeamAdmin,
        teamSystemUsersList,
        updateTeamAdminRoleStatus,
        teamsPage,
    } = useUserPermissions()
    const { tId } = useParams()
    const location = useLocation()
    const { md } = useBreakPoints()
    const { companyId } = useContext(AuthContext)

    const axiosPrivate = useAxiosPrivate()
    const { enqueueSnackbar } = useSnackbar()

    const { onChangeHandlers, state, params } = useTableState({
        columns: userFields,
    })

    const { data, isLoading, isFetching, refetch } = useFetchEntitiesPerPage(
        {
            endPoint: `teams/${tId}/system/users`,
            ...params,
        },
        { enabled: teamSystemUsersList }
    )

    const [open, setOpen] = useState(false)

    const { mutate, isLoading: updating } = useMutation((formData) =>
        axiosPrivate
            .patch(
                `/corporate/${companyId}/team/${tId}/users/${formData?.user_id}/roles/${formData?.id}/status`,
                { status: formData?.status }
            )
            .then((res) => res.data)
    )

    const handleStatusChange = useCallback(
        (checked, row) => {
            mutate(
                {
                    id: row?.id,
                    user_id: row?.user_id,
                    status: checked ? "ACTIVE" : "INACTIVE",
                },
                {
                    onError: (error) => {
                        enqueueSnackbar(
                            `status update failed due to ${error.message}`,
                            { variant: "error" }
                        )
                    },
                    onSuccess: () => {
                        enqueueSnackbar("status updated successfully", {
                            variant: "success",
                        })
                        refetch()
                    },
                }
            )
        },
        [enqueueSnackbar, mutate, refetch]
    )
    const statusButton = useCallback(
        ({ row }) => (
            <StatusButton
                loading={updating}
                status={row?.original?.roles[0]?.status}
                onChange={(checked) =>
                    handleStatusChange(checked, {
                        ...row?.original?.roles[0],
                        user_id: row?.original?.id,
                    })
                }
                disabled={updateTeamAdminRoleStatus}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const roleColumn = useCallback(
        ({ cell }) =>
            cell.getValue()?.map((el) => (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                        m: ".05em",
                        backgroundColor:
                            el?.status === "ACTIVE"
                                ? "success.main"
                                : "error.main",
                        color: "white",
                        borderRadius: "20px",
                        pr: "1em",
                        width: 90,
                    }}
                >
                    <IconButton
                        size="small"
                        sx={{
                            color: "white",
                        }}
                    >
                        {el?.status === "ACTIVE" ? (
                            <TaskAlt fontSize=".1em" />
                        ) : (
                            <HighlightOff fontSize=".1em" />
                        )}
                    </IconButton>
                    <Typography variant="subtitle2" noWrap>
                        {el?.role_name}
                    </Typography>
                </Box>
            )),
        []
    )

    const dateFilter = useCallback(
        (props) => <CustomeDateFilter {...props} />,
        []
    )

    // ADD RENDER CELL METHOD TO COL DEFS FOR STATUS
    const colDefs = useMemo(
        () =>
            userFields.map((el) => {
                if (el.accessorKey === "created_at")
                    return {
                        ...el,
                        Filter: dateFilter,
                    }
                if (el.accessorKey === "status") {
                    return {
                        ...el,
                        Cell: statusButton,
                    }
                }
                if (el.id === "role_name") {
                    return {
                        ...el,
                        Cell: roleColumn,
                    }
                }
                // if (el.accessorKey === "actions") {
                //     return {
                //         ...el,
                //         Cell: actionColumn,
                //     }
                // }
                return el
            }),
        [statusButton, roleColumn, dateFilter]
    )

    const { props } = useTableProps({
        columns: colDefs,
        actionButtonProps: assignTeamAdmin && {
            actionName: "Add Admin",
            onClick: () => setOpen(true),
        },
        exportProps: {
            dataEndPoint: `teams/${tId}/system/users`,
            model: params.filterModel,
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        refetch: refetch,
    })

    if (!teamsPage) return <RestrictedAccess />

    return (
        <Box>
            {assignTeamAdmin && open && (
                <AddTeamAdmin
                    id={location?.state?.id}
                    handleModalClose={() => setOpen(false)}
                    refetch={refetch}
                    open={open}
                />
            )}
            <Box sx={{ backgroundColor: "white" }}>
                <MaterialReactTable
                    data={data?.data?.data || []}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    initialState={{
                        columnPinning: {
                            left: [md ? "name" : null],
                            right: [md ? "actions" : null],
                        },
                    }}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default TeamSystemUsers
