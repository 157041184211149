import React, { useContext } from "react"
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { useQuery } from "react-query"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import AuthContext from "../../Context/auth_provider.jsx"

// View Roles Details Component
function ViewRoleDetails({ handleViewModalClose, roleData, view }) {
    const { companyId, teamId } = useContext(AuthContext)
    const domain = teamId ? `team/${teamId}` : `corporate/${companyId}`

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERIES $ MUTATIONS
    const { data: permissions, isLoading: permissionsIsLoading } = useQuery(
        ["get_corporate_permissions"],
        () =>
            axiosPrivate.get(`${domain}/permissions`, {
                params: {
                    page: -1,
                },
            })
    )
    const { data: role, isLoading: roleIsLoading } = useQuery(
        ["get_role"],
        () =>
            axiosPrivate.get(`${domain}/roles/${roleData?.id}`, {
                params: {
                    page: -1,
                },
            })
    )

    // DATA STRUCTURE for Permissions Category By Name
    const selectedPermissions = permissions?.data?.data?.filter((permission) =>
        role?.data?.data?.permissions?.includes(permission.name)
    )
    const permissionCategories = selectedPermissions?.map(
        (element) => element.statement.resource.split(":")[1]
    )
    const uniquePermissionCategories = [...new Set(permissionCategories)]

    // RENDER
    return (
        !permissionsIsLoading &&
        !roleIsLoading && (
            <Dialog
                maxWidth="md"
                open={view}
                onClose={handleViewModalClose}
                scroll="paper"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Role Details
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            mx: 2,
                            mt: 2,
                        }}
                    >
                        <Box>
                            <TextField
                                variant="outlined"
                                label="Name"
                                defaultValue={roleData?.name}
                                autoComplete="name"
                                fullWidth
                                size="small"
                                disabled
                                sx={{
                                    mb: 1,
                                }}
                            />
                            <Divider />
                        </Box>
                        <Box
                            sx={{
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            {selectedPermissions?.length === 0 ? (
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mx: 2,
                                    }}
                                >
                                    This role did not contain any selected
                                    permissions.
                                </Typography>
                            ) : (
                                <FormControl>
                                    {uniquePermissionCategories.map(
                                        (category) => (
                                            <FormGroup
                                                key={
                                                    category === undefined
                                                        ? "Uncategorised"
                                                        : category
                                                }
                                                sx={{
                                                    mt: 1,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {category === undefined
                                                        ? "UNCATEGORISED"
                                                        : category.toUpperCase()}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        ml: 4,
                                                    }}
                                                >
                                                    {permissions?.data?.data?.map(
                                                        (item) =>
                                                            item.statement.resource.split(
                                                                ":"
                                                            )[1] === category &&
                                                            selectedPermissions
                                                                ?.filter(
                                                                    (el) =>
                                                                        el.id ===
                                                                        item.id
                                                                )
                                                                ?.map(
                                                                    (
                                                                        checked
                                                                    ) => (
                                                                        <Box
                                                                            component="span"
                                                                            sx={{
                                                                                visibility:
                                                                                    "visible",
                                                                                mt: 2,
                                                                                mx: 1,
                                                                                p: 0.7,
                                                                                bgcolor:
                                                                                    "grey.100",
                                                                                color: "grey.800",
                                                                                border: "1px solid",
                                                                                borderColor:
                                                                                    "grey.300",
                                                                                borderRadius: 2,
                                                                                fontSize: 14,
                                                                                fontWeight:
                                                                                    "700",
                                                                            }}
                                                                        >
                                                                            <Tooltip
                                                                                title={
                                                                                    checked.description
                                                                                }
                                                                                arrow
                                                                            >
                                                                                <FormControlLabel
                                                                                    key={
                                                                                        checked.id
                                                                                    }
                                                                                    value={
                                                                                        checked.id
                                                                                    }
                                                                                    control={
                                                                                        <Checkbox
                                                                                            key={
                                                                                                checked.id
                                                                                            }
                                                                                            checked
                                                                                            sx={{
                                                                                                "&.Mui-checked":
                                                                                                    {
                                                                                                        color: "primary.main",
                                                                                                    },
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        checked.name
                                                                                    }
                                                                                    name={
                                                                                        checked.id
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    )
                                                                )
                                                    )}
                                                </Box>
                                            </FormGroup>
                                        )
                                    )}
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        mb: 1,
                        pr: 4,
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                            color: "primary.main",
                        }}
                        onClick={handleViewModalClose}
                    >
                        <Typography>Back</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}
export default ViewRoleDetails
