import React, { useCallback, useMemo, useState } from "react"
import { AttachMoney, Hail, ManageAccounts, People } from "@mui/icons-material"
import { Box, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable from "material-react-table"
import { useSnackbar } from "notistack"
import { Link } from "react-router-dom"
import { DateTime } from "luxon"

import useTableState from "../../Hooks/useTableState.js"
import useTableProps from "../../Hooks/useTableProps.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"
import useFetchEntitiesPerPage from "../../Hooks/useFetchEntitiesPerPage.js"

import customeFilter from "../../Component/customFilterPanel.jsx"
import RestrictedAccess from "../../Component/restrictedAccess.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import StatusButton from "../../Component/statusButton.jsx"
import usePartialUpdateEntity from "../../Hooks/usePartialUpdateEntity.js"
import CreateTeamForm from "./createTeam.jsx"

const teamsCol = [
    {
        accessorKey: "created_at",
        header: "Date",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "name",
        header: "Name",
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        enableColumnFilter: false,
        renderColumnFilterModeMenuItems: customeFilter,
        size: 200,
    },
    {
        accessorKey: "updated_at",
        header: "Updated At",
        size: 200,
        filterVariant: "date",
        renderColumnFilterModeMenuItems: customeFilter,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "action",
        header: "Actions",
        size: 360,
        enableSorting: false,
        enableColumnFilter: false,
    },
]

function Teams() {
    const {
        teamsList,
        teamsPage,
        teamsCreate,
        teamMembersList,
        teamProgramsList,
        teamSystemUsersList,
        teamTransactionsList,
    } = useUserPermissions()
    const { params, state, onChangeHandlers } = useTableState({
        columns: teamsCol,
    })
    const [open, setOpen] = useState(false)

    const exportProcessor = (data) =>
        data?.map((el) => ({
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            user_name: `${el.User?.first_name} ${el.User?.last_name} ${el.User?.last_name}`,
            user_phone: el.phone ?? el.User?.phone,
            start_location: el.start_location,
            end_location: el.end_location,
            note: el.note,
            payment_type: el.payment_type,
            status: el.status,
            payment_status: el.payment_status,
            reason_failed: el.reason_failed,
            trip_type: el.reason_failed,
            updated_at: DateTime.fromISO(el.updated_at).toFormat("ff"),
        }))
    const { enqueueSnackbar } = useSnackbar()
    const {
        data: emergencyTripsData,
        isLoading,
        isFetching,
        refetch,
    } = useFetchEntitiesPerPage(
        {
            endPoint: "teams",
            ...params,
        },
        { enabled: teamsList }
    )
    const { mutate, isLoading: updating } = usePartialUpdateEntity(`teams`)

    // HANDLERS
    const handleStatusChange = useCallback(
        (checked, row) => {
            mutate(
                {
                    id: row?.id,
                    status: checked ? "ACTIVE" : "INACTIVE",
                },
                {
                    onError: (error) => {
                        enqueueSnackbar(
                            `Status update failed due to ${error.message}`,
                            { variant: "error" }
                        )
                    },
                    onSuccess: () => {
                        enqueueSnackbar("Status updated successfully", {
                            variant: "success",
                        })
                        refetch()
                    },
                }
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    const actionColumn = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <StatusButton
                    loading={updating}
                    status={row.original.status}
                    onChange={(checked) =>
                        handleStatusChange(checked, row.original)
                    }
                />
                {teamSystemUsersList && (
                    <Link
                        to={`${row.original.id}/system-users`}
                        state={row.original}
                    >
                        <Tooltip title="System Users">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <ManageAccounts />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {teamMembersList && (
                    <Link to={`${row.original.id}/people`} state={row.original}>
                        <Tooltip title="People">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <People />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {teamProgramsList && (
                    <Link
                        to={`${row.original.id}/programs`}
                        state={row.original}
                    >
                        <Tooltip title="Programs">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <Hail />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                {teamTransactionsList && (
                    <Link
                        to={`${row.original.id}/transactions`}
                        state={row.original}
                    >
                        <Tooltip title="Transactions">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <AttachMoney />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
            </Box>
        ),
        [
            updating,
            teamMembersList,
            teamProgramsList,
            teamSystemUsersList,
            teamTransactionsList,
            handleStatusChange,
        ]
    )
    const colDefs = useMemo(
        () =>
            teamsCol.map((el) => {
                if (el.accessorKey === "action") {
                    return {
                        ...el,
                        Cell: actionColumn,
                    }
                }
                return el
            }),
        [actionColumn]
    )

    const { props } = useTableProps({
        columns: colDefs,
        actionButtonProps: teamsCreate && {
            actionName: "Add Department",
            onClick: () => setOpen(true),
        },
        state: {
            ...state,
            showSkeletons: isLoading,
            showProgressBars: isFetching,
        },
        exportProps: {
            dataEndPoint: `teams`,
            model: params.filterModel,
            formatter: exportProcessor,
        },
        refetch: refetch,
    })

    if (!teamsPage) return <RestrictedAccess />

    return (
        <Box>
            {open && (
                <CreateTeamForm
                    open={open}
                    setOpen={setOpen}
                    refetch={refetch}
                />
            )}
            <Box sx={{ backgroundColor: "white" }}>
                <MaterialReactTable
                    data={emergencyTripsData?.data?.data ?? []}
                    rowCount={emergencyTripsData?.data?.meta_data?.total ?? 0}
                    initialState={{
                        columnPinning: {
                            right: ["action"],
                        },
                    }}
                    {...props}
                    {...onChangeHandlers}
                />
            </Box>
        </Box>
    )
}

export default Teams
