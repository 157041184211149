import * as React from "react"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link as RouterLink } from "react-router-dom"
import { NavigateNext } from "@mui/icons-material"
import useBreakPoints from "../Hooks/useBreakPoints.js"

const breadcrumbNameMap = {
    "/departments": "Departments",
    "/programs/create": "Create Programs",
    "/programs": "Programs",
    "/roles": "Roles",
    "/users": "People",
    "/transactions": "Transactions",
    "/invoice": "Invoice",
    "/topups": "Top ups",
    "/system-users": "System Users",
    "/config": "Config",
    "/vouchers": "Vouchers",
    "/api-keys": "API Keys",
    "/receipts": "Receipts",
    "/discounts": "Discounts",
    "/pre-RIDE": "pre-RIDE",
}

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />
}

function BreadCrumbPaths({ to, pathnames }) {
    const first = `/${pathnames[0]}`

    const { xs, sm } = useBreakPoints()
    const mobile = xs && !sm

    return first !== to ? (
        <div>{pathnames[1]}</div>
    ) : (
        <LinkRouter
            style={{ fontSize: mobile ? 15 : 20 }}
            underline="hover"
            color="inherit"
            to={to}
            key={to}
        >
            {breadcrumbNameMap[to]}
        </LinkRouter>
    )
}

function LastItem({ to, pathnames }) {
    const lastWord = pathnames[pathnames.length - 1]

    return pathnames.length > 1 ? (
        <Typography
            color="black"
            variant="subtitle2"
            sx={{ fontSize: { xs: 15, sm: 20 } }}
            key={to}
        >
            {lastWord.charAt(0).toUpperCase() + lastWord.slice(1)}
        </Typography>
    ) : (
        <Typography
            color="black"
            variant="subtitle2"
            sx={{ fontSize: { xs: 15, md: 20 } }}
            key={to}
        >
            {breadcrumbNameMap[to]}
        </Typography>
    )
}

function Page({ location }) {
    const pathnames = location.pathname.split("/").filter((x) => x)

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            maxItems={2}
            separator={<NavigateNext fontSize="small" />}
        >
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `/${pathnames.slice(0, index + 1).join("/")}`

                return last ? (
                    <LastItem to={to} pathnames={pathnames} />
                ) : (
                    <BreadCrumbPaths to={to} pathnames={pathnames} />
                )
            })}
        </Breadcrumbs>
    )
}

export default Page
